import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchClients} from "../../../slices/clientListSlice.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import ClientsTable from "./components/ClientsTable.js";
import {resetStateData} from "../../../slices/clientSlice.js";

const ClientListPage = () => {
    const dispatch = useDispatch();

    const {data: clients, loading, pagination} = useSelector((state) => state.clientList);
    const [searchParams, setSearchParams] = useState({page: 1, search: '', filters: {}})

    useEffect(() => {
        dispatch(resetStateData());
    }, []);

    useEffect(() => {
        dispatch(fetchClients(searchParams));
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams({
            page: page,
            filters: searchParams.filters,
            search: searchParams.search
        })
    };

    const handleQueryChange = (query) => {
        setSearchParams({
            page: 1,
            filters: searchParams.filters,
            search: query
        })
    }

    const showTable = () => {
        return !loading;
    }

    return (
        <div>
            <ListHeaderLayout
                title={'Клиенти'}
                createLink={'/clients/create/edit'}
                onQueryChange={handleQueryChange}
            />
            <ClientsTable showTable={() => showTable()} clients={clients}/>
            <PaginationComponent
                pagination={pagination}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default ClientListPage;
