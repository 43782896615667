import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import {changeAttributeReducer, resetStateDataReducer, setDataReducer, setStatusReducer} from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        name: '',
        email: '',
        identificationId: '',
        clientType: null,
        address: '',
        city: '',
        zip: '',
        country: 'България',
        phoneNumber: '',
        molField: '',
        additionalInformation: '',
        vatNumber: '',
        discount: 0,
        isInsurer: false
    },
    loading: false,
    status: 'idle',
    errors: []
};

export const fetchClient = fetchItem('client/fetchClient', '/clients')
export const saveClient = saveItem('client/saveClient', '/clients');

const clientItem = createSlice({
    name: 'clientItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState),
        setData: setDataReducer,
        setStatus: setStatusReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchClient, saveClient)
    },
});

export default clientItem.reducer;
export const {
    changeAttribute,
    resetStateData,
    setData,
    setStatus
} = clientItem.actions;