import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
}

export const fetchOrderLineTypes = fetchItems(
    'orderLineTypes/fetchOrderLineTypes',
    '/order-line-types'
);

const orderLineTypeListSlice = createSlice({
    name: 'orderLineTypes',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchOrderLineTypes)
    },
});

export default orderLineTypeListSlice.reducer;