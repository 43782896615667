import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import {getArticleStockSchema} from "../../../../utils/schemas.js";
import {setErrorsFromValidation} from "../../../../utils/validation.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import {changeAttribute} from "../../../../slices/articleStockSlice.js";
import {useDispatch, useSelector} from "react-redux";
import {fetchSuppliers} from "../../../../slices/supplierListSlice.js";
import {getPriceWithoutVatFloat, toFloat} from "../../../../utils/functions.js";

const EditArticleStockModal = (
    {
        onClose,
        onSubmit,
        article
    }
) => {
    const [costPriceWithoutVat, setCostPriceWithoutVat] = useState(0);
    const [errors, setErrors] = useState({});
    const [availableSuppliers, setAvailableSuppliers] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    const dispatch = useDispatch();
    const {data: suppliers, suppliersLoading} = useSelector((state) => state.supplierList);
    const {data: articleStock} = useSelector((state) => state.articleStock);

    useEffect(() => {
        setCostPriceWithoutVat(getPriceWithoutVatFloat(articleStock.costPrice));
        dispatch(fetchSuppliers());
    }, []);

    useEffect(() => {
        if (article?.articleStocks) {
            const availableSuppliers = suppliers.filter(
                (supplier) => !article.articleStocks.some(
                    (stock) => stock.supplier.id === supplier.id
                )
            );
            setAvailableSuppliers(availableSuppliers);
        }
    }, [article.articleStocks, suppliers, setAvailableSuppliers]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleCostPriceChangeWithoutVat = (name, value) => {
        setCostPriceWithoutVat(value);
        handleAttributeChange('costPrice', toFloat(value * 1.2));
    }

    const handleCostPriceChange = (name, value) => {
        setCostPriceWithoutVat(toFloat(value - value / 6));
        handleAttributeChange(name, value)
    }

    const handleUpdate = async () => {
        setSaveButtonDisabled(true);
        setErrors({});
        try {
            await getArticleStockSchema().validate(articleStock, {abortEarly: false});
            onSubmit(articleStock);
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
            setSaveButtonDisabled(false);
        }
    }

    return (
        <Modal show={true} onHide={onClose} animation={false} size="lg">
            <Modal.Header closeButton>
                <h5>Редактирай наличност</h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4}>
                        <SelectInput
                            name={'supplier'}
                            value={articleStock.supplier?.id}
                            onChange={handleAttributeChange}
                            label={'Доставчик'}
                            selectOptions={articleStock.id !== null ? [articleStock.supplier] : availableSuppliers}
                            disabled={articleStock.id !== null}
                            errors={errors}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormNumericInput
                            name={'costPrice'}
                            onChange={handleCostPriceChange}
                            label={'Цена с ДДС'}
                            value={articleStock.costPrice}
                            errors={errors}
                        />
                        <FormNumericInput
                            name={'costPriceWithoutVat'}
                            onChange={handleCostPriceChangeWithoutVat}
                            label={'Цена без ДДС (нето)'}
                            value={costPriceWithoutVat}
                            errors={errors}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormNumericInput
                            name={'stockQuantity'}
                            onChange={handleAttributeChange}
                            label={'Наличност'}
                            value={articleStock.stockQuantity}
                            disabled={articleStock.id}
                            errors={errors}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>Откажи</Button>
                {!suppliersLoading && (
                    <Button variant="success" disabled={saveButtonDisabled} onClick={handleUpdate}>Запази</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default EditArticleStockModal;