import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";
import React from "react";

const SupplierForm = ({
    supplier,
    onAttributeChange,
    errors
                             }) => {
    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col md={3}>
                        <FormInput label="Име" name="name" value={supplier.name}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
};

export default SupplierForm;