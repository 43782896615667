import SelectInput from "../../../../../components/Elements/SelectInput.js";
import React from "react";
import {useSelector} from "react-redux";

const PaymentTypeSelect = (
    {
        paymentType,
        errors,
        onAttributeChange
    }
) => {
    const {data: paymentTypes} = useSelector((state) => state.paymentTypeList);

    return (
        <SelectInput
            label={'Начин плащане'}
            value={paymentType ? paymentType.id : ''}
            name={'paymentType'}
            errors={errors}
            onChange={onAttributeChange}
            selectOptions={paymentTypes}
        />
    )
}

export default PaymentTypeSelect;