import DateInput from "../../../../../components/Elements/DateInput.js";
import React from "react";

const PaymentDueDateInput = (
    {
        paymentDueDate,
        onAttributeChange,
        errors,
        disabled = false
    }
) => {

    return (
        <DateInput label={'Дата падеж'} dateValue={paymentDueDate} name={'paymentDueDate'}
                   onDateValueChange={onAttributeChange} errors={errors} disabled={disabled}/>

    )
}

export default PaymentDueDateInput;