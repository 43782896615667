import {Routes, Route} from "react-router-dom";
import UserListPage from "../pages/Users/List/UserListPage.js";
import UserEditPage from "../pages/Users/Single/UserEditPage.js";
import {hasPermission} from "../utils/permissions.js";
import CarModelListPage from "../pages/CarModels/List/CarModelListPage.js";
import CarModelViewPage from "../pages/CarModels/Single/CarModelViewPage.js";
import RoleListPage from "../pages/Roles/List/RoleListPage.js";
import RolePage from "../pages/Roles/Single/RolePage.js";
import CarModelEditPage from "../pages/CarModels/Single/CarModelEditPage.js";
import CarManufacturerListPage from "../pages/CarManufacturers/List/CarManufacturerListPage.js";
import CarManufacturerViewPage from "../pages/CarManufacturers/Single/CarManufacturerViewPage.js";
import CarManufacturerEditPage from "../pages/CarManufacturers/Single/CarManufacturerEditPage.js";
import SupplierEditPage from "../pages/Suppliers/Single/SupplierEditPage.js";
import SupplierListPage from "../pages/Suppliers/List/SupplierListPage.js";

const SettingRoutes = ({
    user
                    }) => (
    <Routes>
        <Route path='/users' element={<UserListPage />} />
        <Route path='/users/:id/edit' element={hasPermission(user, 'update-users') ? <UserEditPage /> : <UserListPage/>} />
        <Route path='/car-models' element={<CarModelListPage />} />
        <Route path='/car-models/:id' element={<CarModelViewPage />} />
        <Route path='/car-models/:id/edit' element={hasPermission(user, 'update-car-models') ? <CarModelEditPage /> : <CarModelViewPage />} />
        <Route path='/roles' element={<RoleListPage />} />
        <Route path='/roles/:id/edit' element={<RolePage />} />
        <Route path='/car-manufacturers' element={<CarManufacturerListPage />} />
        <Route path='/car-manufacturers/:id' element={<CarManufacturerViewPage />} />
        <Route path='/car-manufacturers/:id/edit' element={hasPermission(user, 'update-car-models') ? <CarManufacturerEditPage /> : <CarManufacturerViewPage />} />
        <Route path='/suppliers' element={hasPermission(user, 'manage-suppliers') ? <SupplierListPage /> : <></>} />
        <Route path='/suppliers/:id/edit' element={hasPermission(user, 'manage-suppliers') ? <SupplierEditPage /> : <></>} />
    </Routes>
);

export default SettingRoutes;