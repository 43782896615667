import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchCarManufacturer,
    resetStateData,
} from "../../../slices/carManufacturerSlice.js";
import {useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import CarManufacturerNav from "./components/CarManufacturerNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import {processIdInformation} from "../../../utils/functions.js";

const CarManufacturerViewPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: carManufacturer, loading} = useSelector((state) => state.carManufacturer);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCarManufacturer({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    const getTitle = () => {
        return `Прозиводител № ${id}`;
    }

    const showPage = () => {
        return !loading;
    }

    return (
        <SingleViewLayout
            title={getTitle()}
            navComponent={CarManufacturerNav}
            navProps={{id:id, activeKey:'view'}}
            showPage={() => showPage()}
        >
            <Row>
                <div>
                    <div><b>Име: </b>{carManufacturer.name}</div>
                </div>
            </Row>
        </SingleViewLayout>
    );
};

export default CarManufacturerViewPage;
