import {Routes, Route} from "react-router-dom";
import ArticleListPage from "../pages/Articles/List/ArticleListPage.js";
import ArticleEditPage from "../pages/Articles/Single/ArticleEditPage.js";
import ArticleOrdersPage from "../pages/Articles/Single/ArticleOrdersPage.js";
import {hasPermission} from "../utils/permissions.js";
import ArticleViewPage from "../pages/Articles/Single/ArticleViewPage.js";
import ArticleFilesPage from "../pages/Articles/Single/ArticleFilesPage.js";
import ArticleStocksPage from "../pages/Articles/Single/ArticleStocksPage.js";
import SingleArticleWrapper from "../pages/Articles/SingleArticleWrapper.js";

const ArticleRoutes = ({user}) => (
    <Routes>
        <Route index element={<ArticleListPage />} />
        <Route
                path={':id'}
                element={<SingleArticleWrapper />}
            >
            <Route index element={<ArticleViewPage />} />
            <Route path={'edit'} element={hasPermission(user, 'update-articles') ? <ArticleEditPage /> : <ArticleViewPage />}  />
            <Route path={'files'} element={hasPermission(user, 'update-articles') ? <ArticleFilesPage /> : <ArticleViewPage />}  />
            <Route path={'orders'} element={<ArticleOrdersPage />} />
            <Route path={'files'} element={hasPermission(user, 'update-articles') ? <ArticleFilesPage /> : <ArticleViewPage />}  />
            <Route path={'stocks'} element={hasPermission(user, 'get-article-stocks') ? <ArticleStocksPage /> : <ArticleViewPage />}  />
        </Route>
    </Routes>
);

export default ArticleRoutes;