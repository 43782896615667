import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    saveOrder,
    changeAttribute,
    updateOrderLine,
    addOrderLine,
    setStatus,
    lineExists
} from "../../../slices/orderSlice.js";
import {useParams, useNavigate} from "react-router-dom";
import {processIdInformation, processSubmit} from "../../../utils/functions.js";
import OrderForm from "./components/OrderForm.js";
import OrderLinesComponent from "./components/OrderLinesComponent.js";
import {getOrderSchema} from "../../../utils/schemas.js";
import OrderNav from "./components/OrderNav.js";
import {fetchPartners} from "../../../slices/partnerListSlice.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";
import {resetStateData as resetStateCarListData} from '../../../slices/carListSlice.js';
import {resetStateData as resetStateClientData} from "../../../slices/clientSlice.js";
import {resetStateData as resetStateCarData} from "../../../slices/carSlice.js";

const OrderEditPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data: order, loading, errors: stateErrors, status} = useSelector((state) => state.order);
    const {data: partners, loading: partnersLoading} = useSelector((state) => state.partnerList)

    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState('');

    const [initialOrderStatus, setInitialOrderStatus] = useState(null);

    useEffect(() => {
        setTitle(idIsInteger ? `Поръчка № ${id}` : 'Нова поръчка');
    }, [id]);

    useEffect(() => {
        if (!partners.length) {
            dispatch(fetchPartners());
        }

        return () => {
            dispatch(resetStateCarListData());
            dispatch(resetStateClientData());
            dispatch(resetStateCarData());
        };
    }, [id]);

    useEffect(() => {
        if (initialOrderStatus === null) {
            setInitialOrderStatus(order.orderStatus);
        }
    }, [order.orderStatus]);

    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазена поръчка - ${order.id}`));
            dispatch(setStatus('idle'));
            navigate(`/orders/${order.id}`);
        }
    }, [status]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleLineUpdate = (orderLine) => {
        if (isNaN(orderLine.id) && !lineExists(order, orderLine)) {
            dispatch(addOrderLine(orderLine))
        } else {
            dispatch(updateOrderLine(orderLine));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, getOrderSchema(order), saveOrder, dispatch, order);
    };

    const showForm = () => {
        //fix so that date is not set if user comes from editing another order
        return !loading &&
            !partnersLoading &&
            ((id === 'create' && !order.id) || (parseInt(id) === order.id));
    }

    return (
        <SingleEditLayout
            title={title}
            showForm={showForm()}
            onSubmit={handleSubmit}
            stateErrors={stateErrors}
            navComponent={OrderNav}
            navProps={{id: id, activeKey: 'edit'}}
            canSubmit={initialOrderStatus?.label !== 'cancelled'}
            >
            <OrderForm
                order={order}
                errors={errors}
                partners={partners}
                onAttributeChange={handleAttributeChange}
            />
            <OrderLinesComponent
                order={order}
                onLineUpdate={handleLineUpdate}
                errors={errors}
                />
        </SingleEditLayout>
    );
};

export default OrderEditPage;
