import { Pagination } from "react-bootstrap";
import React, {useEffect, useState} from "react";

const PaginationComponent = (
    {
        onPageChange,
        pagination
    }) => {
    const [menuPages, setMenuPages] = useState([]);

    useEffect(() => {
        getPageNumbers();
    }, [pagination]);

    const getPageNumbers = () => {
        let pages = [];

        const startPage = Math.max(1, pagination.currentPage - 4);
        const endPage = Math.min(pagination.lastPage, pagination.currentPage + 4);
        if (pagination.currentPage > 5) {
            pages.push(1, '...');
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (pagination.currentPage < pagination.lastPage - 4) {
            pages.push('...', pagination.lastPage);
        }
        setMenuPages(pages);
    };

    return (
        <div className="mt-2">
            <Pagination>
                <Pagination.First onClick={() => onPageChange(1)} disabled={pagination.currentPage === 1}/>
                <Pagination.Prev
                    onClick={() => onPageChange(pagination.currentPage - 1)}
                    disabled={pagination.currentPage === 1}
                />
                {menuPages.map((page, index) =>
                    page === '...' ? (
                        <Pagination.Ellipsis key={index}/>
                    ) : (
                        <Pagination.Item
                            key={index}
                            active={page === pagination.currentPage}
                            onClick={() => onPageChange(page)}
                        >
                            {page}
                        </Pagination.Item>
                    )
                )}
                <Pagination.Next
                    onClick={() => onPageChange(pagination.currentPage + 1)}
                    disabled={pagination.currentPage === pagination.lastPage}
                />
                <Pagination.Last
                    onClick={() => onPageChange(pagination.lastPage)}
                    disabled={pagination.currentPage === pagination.lastPage}
                />
            </Pagination>
            <p>Общо: {pagination.totalItems}</p>
        </div>
    );
};

export default PaginationComponent;
