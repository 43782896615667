import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";

const ConfirmationModalComponent = ({
    loading = false,
    show = false,
    titleText = 'Сигурен ли си?',
    bodyText = 'Моля потвърди.',
    declineText = 'Не',
    confirmText = 'Да',
    onDecline,
    onConfirm
                                    }) => {
    return (
        <>
            {show && (
                <Modal
                    show={show}
                    onHide={loading ? null : onDecline}
                    animation={false}
                    size={"lg"}
                    backdrop={loading ? 'static' : true}
                    keyboard={!loading}
                > <Modal.Header closeButton={!loading}>
                    <Modal.Title>{titleText}</Modal.Title>
                </Modal.Header>

                    <Modal.Body>
                        <p>{bodyText}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger"
                            onClick={onDecline}
                            style={{visibility: loading ? 'hidden' : 'visible'}}
                        >
                            {declineText}
                        </Button>
                        <Button
                            variant="success"
                            onClick={onConfirm}
                            style={{visibility: loading ? 'hidden' : 'visible'}}
                        >
                            {confirmText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}

export default ConfirmationModalComponent;