import {createAsyncThunk} from "@reduxjs/toolkit";
import apiClient from "../axios.js";

export const fetchItems = (typePrefix, path) => {
    return createAsyncThunk(
        typePrefix,
        async (
            {
                search = '',
                page = 1,
                id,
                perPage = null,
                filters = [],
                sort = {},
                extend = [],
                skip = []
            } = {}) => {
            let modifiedPath = path;
            if (id) {
                modifiedPath = modifiedPath.replace('[ID_PLACEHOLDER]', id)
            }

            let queryParams = {};
            queryParams['page'] = page;
            if (search) {
                queryParams['search'] = search;
            }
            if (Object.keys(filters).length > 0) {
                Object.entries(filters).forEach(([key, value]) => {
                    queryParams[`filters[${key}]`] = value;
                });
            }

            if (Object.keys(extend).length > 0) {
                Object.entries(extend).forEach(([key, value]) => {
                    queryParams[`extend[${key}]`] = value;
                });
            }

            if (Object.keys(skip).length > 0) {
                Object.entries(skip).forEach(([key, value]) => {
                    queryParams[`skip[${key}]`] = value;
                });
            }

            if (Object.keys(sort).length > 0) {
                Object.entries(sort).forEach(([key, value]) => {
                    queryParams[`sort[${key}]`] = value;
                });
            }
            if (perPage !== null) {
                queryParams['perPage'] = perPage
            }

            let queryParamsString = new URLSearchParams(queryParams).toString();
            modifiedPath += `?${queryParamsString}`;

            const response = await apiClient.get(modifiedPath);
            return response.data;
        });
}

export const fetchFileItems = (typePrefix, path) => {
    return createAsyncThunk(
        typePrefix,
        async (
            {
                filesItemPath,
                id
            } = {}) => {
            const response = await apiClient.get(`${filesItemPath}/${path}/${id}`);
            return response.data;
        }
    )
}

export const deleteItem = (typePrefix) => {
    return createAsyncThunk(
        typePrefix,
        async ({path}) => {
            const response = await apiClient.delete(path);
            return response.data;
        }
    )
}

export const uploadItem = (typePrefix) => {
    return createAsyncThunk(
        typePrefix,
        async ({path, formData}, {rejectWithValue}) => {
            let response;
            try {
                response = await  apiClient.post(path, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                return response.data;
            } catch (error) {
                if (error.response.status === 500) {
                    return rejectWithValue(['Сървър грешка. Обърни се към IT.']);
                }
                if (error.response.data.error) {
                    return rejectWithValue([error.response.data.error]);
                }
                return rejectWithValue(error.response.data.errors);
            }
        }
    )
}

export const fetchItem = (typePrefix, path) => {
    return createAsyncThunk(
        typePrefix,
        async ({id, extend = []}) => {
            let queryParams = {};

            if (Object.keys(extend).length > 0) {
                Object.entries(extend).forEach(([key, value]) => {
                    queryParams[`filters[${key}]`] = value;
                });
            }
            let queryParamsString = new URLSearchParams(queryParams).toString();

            let response;
            if (id && path.includes('[ID_PLACEHOLDER]')) {
                const updatedPath = path.replace('[ID_PLACEHOLDER]', id) + queryParamsString;
                response = await apiClient.get(updatedPath);
            } else if (id) {
                response = await apiClient.get(`${path}/${id}${queryParamsString}`);
            } else {
                response = await apiClient.get(`${path}${queryParamsString}`);
            }
            return response.data;
        });
}

export const saveItem = (typePrefix, path) => {

    return createAsyncThunk(
        typePrefix,
        async ({ id, item}, {rejectWithValue}) => {
            let response;
            try {
                if (id) {
                    response = await apiClient.put(`${path}/${id}`, item);
                } else {
                    response = await apiClient.post(`${path}`, item);
                }
                return await response.data;
            } catch (error) {
                if (error.response.status === 500) {
                    return rejectWithValue(['Сървър грешка. Обърни се към IT.']);
                }
                if (error.response.data.error) {
                    return rejectWithValue([error.response.data.error]);
                }
                return rejectWithValue(error.response.data.errors);
            }
        }
    );
}

export const saveItemsBatch = (typePrefix, path) => {
    return createAsyncThunk(
        typePrefix,
        async ({items}, {rejectWithValue}) => {
            let response;
            try {
                response = await apiClient.post(path, items);
                return await response.data;
            } catch (error) {
                if (error.response.status === 500) {
                    return rejectWithValue(['Сървър грешка. Обърни се към IT.']);
                }
                if (error.response.data.error) {
                    return rejectWithValue([error.response.data.error]);
                }
                return rejectWithValue(error.response.data.errors);
            }
        }
    )
}

