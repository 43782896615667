import React from 'react';
import { Form } from 'react-bootstrap';

const FormRadio = (
    {
        options,
        selectedValue,
        onRadioChange,
        name,
        label,
        errors,
        inline = false,
        disabled = false
    }) => {

    const handleRadioChange = ({target: {value}}) => {
        if (value === 'true' || value === 'false') {
            onRadioChange(name, JSON.parse(value));
        } else {
            onRadioChange(name, parseInt(value));
        }
    };

    return (
        <Form.Group>
            <div>
                {label && <Form.Label>{label}</Form.Label>}
            </div>
            {options.map((option) => (
                <Form.Check
                    key={option.value}
                    id={`radio-${name}-${option.value}`}
                    name={name}
                    type="radio"
                    label={option.label}
                    value={option.value}
                    checked={selectedValue === option.value}
                    onChange={handleRadioChange}
                    inline={inline} // Add inline support
                    disabled={disabled}
                />
            ))}
            <Form.Control
                type="hidden"
                isInvalid={!!errors[name]}
                value={selectedValue}
            />
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default FormRadio;
