import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {setBrowserTabTitle} from "../../../slices/informationMessagesSlice.js";
import apiClient from "../../../axios.js";
import {resetStateData} from "../../../slices/homeListSlice.js";
import {useDispatch} from "react-redux";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import HomeOrdersCard from "../../Home/HomeOrdersCard.js";
import LoadingComponent from "../../../components/LoadingComponent.js";
import {getSummaryTitle} from "../../../utils/functions.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import HomeBox from "../../Home/components/HomeBox.js";

const SummaryOrders = () => {
    const dispatch = useDispatch();
    const {summaryName} = useParams();
    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState('loading');
    const title = `Справка - ${getSummaryTitle(summaryName)}`;
    const [homeBoxes, setHomeBoxes] = useState([]);

    const fetchOrders = async ()  => {
        setStatus('loading');
        const response = await apiClient.get(`/orders/summary/${summaryName}`);
        setOrders(response.data.data);
        const homeBoxesResponse = await apiClient.get('/orders/home-boxes-counts');
        setHomeBoxes(homeBoxesResponse.data.data);
        setStatus('loaded');
    }

    useEffect(() => {

        const fetchData = async () => {
            dispatch(setBrowserTabTitle(title));
            await fetchOrders();
        };

        fetchData();

        return () => {
            dispatch(resetStateData());
        };
    }, [dispatch, summaryName]);

    return (
        <div className={'position-relative'}>
            <ListHeaderLayout title={title}/>
            {status === 'loaded' ? (
                <>
                    <Col md={12} className={'position-relative'}>
                        {homeBoxes.length > 0 ? (
                            <Row>
                                {homeBoxes.map((box) => (
                                    <Col md={3} key={box.label}>
                                        <HomeBox title={getSummaryTitle(box.label)} count={box.count} link={box.label}
                                                 color={box.color}/>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <LoadingComponent/>
                        )}
                    </Col>
                    <HomeOrdersCard title={`${getSummaryTitle(summaryName)} ${orders.length} поръчки`} orders={orders} onOrderSave={fetchOrders}/>
                </>
            ) : (
                <LoadingComponent/>
            )}
        </div>

    )
}

export default SummaryOrders;