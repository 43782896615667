import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchClientOrders, resetStateData as resetOrderListStateData, setCurrentPage} from "../../../slices/orderListSlice.js";
import React, {useEffect, useState} from "react";
import OrdersTable from "../../Orders/List/components/OrdersTable.js";
import ClientNav from "./components/ClientNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import PaginationComponent from "../../../components/PaginationComponent.js";

const ClientOrdersPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {data: orders, loading, pagination} = useSelector((state) => state.orderList);
    const {data: client} = useSelector((state) => state.client);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Клиент № ${id} - ${client.name}` );
    }, [client.id]);

    useEffect(() => {
        return () => {
            dispatch(resetOrderListStateData());
        }
    }, []);

    const handlePageChange = (page) => {
        dispatch(setCurrentPage(page));
    };

    const fetchOrders = () => {
        dispatch(fetchClientOrders({page: pagination.currentPage, id: id}));
    }

    useEffect(() => {
            fetchOrders();
    }, [dispatch, pagination.currentPage, id]);

    const showTable = () => {
        return !loading && client.id;
    }

    return (
        <SingleViewLayout
            title={title}
            navComponent={ClientNav}
            navProps={{id: id, activeKey: 'orders'}}
            showPage={showTable()}
        >
            <OrdersTable data={orders} showTable={showTable()} onItemSave={fetchOrders} />
            <PaginationComponent onPageChange={handlePageChange} pagination={pagination}/>

        </SingleViewLayout>
    )
}

export default ClientOrdersPage;