import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {getHpFromKw, processIdInformation} from "../../../utils/functions.js";
import Row from "react-bootstrap/Row";
import CarNav from "./components/CarNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import Col from "react-bootstrap/Col";
import ViewField from "../../../components/Elements/ViewField.js";

const CarViewPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);

    const {data: car, loading} = useSelector((state) => state.car);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle( idIsInteger ? `Кола № ${id} - ${car.registrationNumber}` : 'Нова Кола');
    }, [car.id]);

    const showPage = () => {
        return !loading && car.id;
    }

    return (
        <SingleViewLayout
            title={title}
            navComponent={CarNav}
            navProps={{id:id, activeKey: 'view'}}
            showPage={showPage()}
            >
            <Row>
                <Col md={4}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Клиент'} value={car.client?.name}/>
                        <ViewField label={'Рег. номер'} value={car.registrationNumber}/>
                        <ViewField label={'Марка/Модел'} value={`${car.carModel?.carManufacturer.name} ${car.carModel?.name}`}/>
                        <ViewField label={'Модификация'} value={car.modification}/>
                        <ViewField label={'Кубатура'} value={car.cubature}/>
                        <ViewField label={'Киловати (KW)'} value={car.kw}/>
                        <ViewField label={'Конски сили (HP)'} value={getHpFromKw(car.kw)}/>
                        <ViewField label={'ВИН'} value={car.vinNumber}/>
                        <ViewField label={'Първа регистрация'} value={car.firstRegisteredAt}/>
                        <ViewField label={'Код боя'} value={car.paintCode}/>
                    </Col>
                </Col>
                <Col md={12}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <h6>Допълнителна информация</h6>
                        <ViewField value={car.additionalInformation} parseHtml={true}/>
                    </Col>
                </Col>
            </Row>
        </SingleViewLayout>
    );
};

export default CarViewPage;
