import {NavLink, useLocation} from "react-router-dom";
import React from "react";

const MenuNavLink = (
    {
        to,
        icon,
        text
    }
) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <NavLink
            to={isActive ? '#' : to}
            className={isActive ? 'disabled-link' : ''}
            onClick={(e) => isActive && e.preventDefault()}
        >
            {icon} {text}
        </NavLink>
    );
}

export default MenuNavLink;