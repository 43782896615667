import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_HOST_URL,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            if (localStorage.getItem('isAuthenticated') === 'true') {
                window.location.href = '/logout';
            } else {
                window.location.href = '/';
            }
        }
        return Promise.reject(error);
    }
);

export default apiClient;