import FormNumericInput from "../../../../../components/Elements/FormNumericInput.js";
import React from "react";

const MileageInput = (
    {
        order,
        errors,
        onAttributeChange
    }
) => {
    return (
        <FormNumericInput name={'mileage'} label={'Пробег'} errors={errors} onChange={onAttributeChange}
                          value={order.mileage}
        />
    )
}

export default MileageInput;