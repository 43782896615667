import {Card} from "react-bootstrap";
import ButtonLink from "../../../components/Elements/ButtonLink.js";

const HomeBox = (
    {
        count,
        title,
        link,
        color
    }
) => {
    return (
        <Card className={'mb-3'}>
            <Card.Header>
                <h5 className={'text-center'}><ButtonLink to={`/orders/summary/${link}`} style={{'backgroundColor': color}}>{title}</ButtonLink></h5>
            </Card.Header>
            <Card.Body>
                <h3 className={'home-box-count text-center'} style={{'color':color}}>{count}</h3>
            </Card.Body>
        </Card>
    )
}

export default HomeBox;