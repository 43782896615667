import {Button, Card } from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import StandardTable from "../../../../components/StandardTable.js";

const UsersTable = (
    {
        users,
        showTable
                   }) => {
    const headers = ['№', 'Име', 'Email', ''];

    return (
        <Card>
            {showTable() ? (
                <StandardTable headers={headers}>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>
                                <Button variant="link">
                                    <Link to={`/settings/users/${user.id}/edit`}>Редактирай</Link>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default UsersTable;