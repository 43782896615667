import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
}
export const fetchArticleTypes = fetchItems('articleTypes/fetchArticleTypes', '/article-types');

const articleTypeListSlice = createSlice({
    name: 'articleTypes',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchArticleTypes)
    },
});

export default articleTypeListSlice.reducer;