import Col from "react-bootstrap/Col";
import {Card, Table} from "react-bootstrap";
import {formatDateTimeToBeautifulString} from "../../../../utils/dateFunctions.js";
import Row from "react-bootstrap/Row";
import React from "react";

const ArticleStockChangesTable = (
    {
        articleStockChanges
    }) => {
    return (
        <Row className={'mt-5'}>
            <Col md={12}>
                <Card>
                    <Card.Header>Последни промени</Card.Header>
                    <Card.Body>
                        <Table bordered striped>
                            <thead>
                            <tr>
                                <th>Доставчик</th>
                                <th>Потребител</th>
                                <th>Промяна наличност</th>
                                <th>Наличност след промяна</th>
                                <th>Бележка</th>
                                <th>Дата</th>
                            </tr>
                            </thead>
                            <tbody>
                            {articleStockChanges.map((articleStockChange) => (
                                <tr key={`article-stock-change-${articleStockChange.id}`}>
                                    <td>{articleStockChange.articleStock.supplier.name}</td>
                                    <td>{articleStockChange.user.name}</td>
                                    <td>{articleStockChange.quantity}</td>
                                    <td>{articleStockChange.stockQuantityAfterChange}</td>
                                    <td>{articleStockChange.note}</td>
                                    <td>{formatDateTimeToBeautifulString(articleStockChange.createdAt)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ArticleStockChangesTable;