import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, saveArticle, setStatus} from "../../../slices/articleSlice.js";
import { processIdInformation, processSubmit} from "../../../utils/functions.js";
import ArticleForm from "./components/ArticleForm.js";
import {fetchQuantityTypes} from "../../../slices/quantityTypeSlice.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import ArticleNav from "./components/ArticleNav.js";
import {getArticleSchema} from "../../../utils/schemas.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";

const ArticleEditPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: article, loading, errors: stateErrors, status} = useSelector((state) => state.article);
    const {data: quantityTypes, loading: quantityTypesLoading} = useSelector((state) => state.quantityTypeStatusList);
    const {data: articleTypes, loading: articleTypesLoading} = useSelector((state) => state.articleTypeList);

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (!quantityTypes.length) {
            dispatch(fetchQuantityTypes());
        }
    }, []);

    useEffect(() => {
        setTitle(idIsInteger ? `Артикул № ${id} - ${article.name}` : 'Нов Артикул');
    }, [article.id]);

    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазен артикул - ${article.name}`));
            dispatch(setStatus('idle'));
            navigate(`/articles/${article.id}`);
        }
    }, [status]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSettingsChange = (attribute, value) => {
        const settings = {...article.settings};
        settings[attribute] = value;
        dispatch(changeAttribute({attribute: 'settings', value: settings}));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, getArticleSchema(article), saveArticle, dispatch, article);
    };

    const showForm = () => {
        return !loading && !quantityTypesLoading && !articleTypesLoading
    }

    return (
        <SingleEditLayout
            title={title}
            showForm={showForm()}
            onSubmit={handleSubmit}
            navComponent={ArticleNav}
            navProps={{id: id, activeKey: 'edit'}}
            stateErrors={stateErrors}
        >
            <ArticleForm
                article={article}
                onSubmit={handleSubmit}
                onAttributeChange={handleAttributeChange}
                errors={errors}
                quantityTypes={quantityTypes}
                articleTypes={articleTypes.filter(type => !type.isSettingValue)}
                onSettingsChange={handleSettingsChange}
            />
        </SingleEditLayout>
    );
};

export default ArticleEditPage;
