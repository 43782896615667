import React from 'react';
import './LoadingComponent.css'; // Import the CSS file

const LoadingComponent = (
    {
        className = ''
    }
) => {
    return (
        <div className={`loading-overlay ${className}`}>
            <div className="loading-spinner"></div>
        </div>
    );
};

export default LoadingComponent;
