import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    errors: []
}

export const fetchRoles = fetchItems(
    'roles/fetchRoles', '/roles'
);

const roleListSlice = createSlice({
    name: 'roles',
    initialState: initialState,
    reducers: {
        resetStateData: resetStateDataReducer(initialState),
        setCurrentPage: setCurrentPageReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchRoles)
    },
});

export const {
    setCurrentPage,
    resetStateData
} = roleListSlice.actions;

export default roleListSlice.reducer;