import React from "react";
import {Table} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FileElement from "./FileElement.js";

const FileListComponent = ({
    files = [],
    itemsPath,
    id
                           }) => {

    const path = `${process.env.REACT_APP_API_HOST_URL}/${itemsPath}/files/${id}`;

    function separateFiles(fileNames) {
        const images = [];
        const otherFiles = [];

        fileNames.forEach(fileName => {
            const extension = fileName.split('.').pop().toLowerCase();
            if (['jpg', 'jpeg', 'png'].includes(extension)) {
                images.push(fileName);
            } else {
                otherFiles.push(fileName);
            }
        });

        return {images, otherFiles};
    }

    const {images, otherFiles} = separateFiles(files);

    return (
        <>
            {images.length > 0 && (
                <Row>
                    <h5>Снимки</h5>
                    {images.map((image, index) => (
                        <Col key={index} md={3} className={'image-in-list-component-box'}>
                            <FileElement fileName={image} filePath={path} />
                        </Col>
                    ))}
                </Row>
            )}
            {otherFiles.length > 0 && (
                <Row className={'mt-2'}>
                    <h5>Документи</h5>
                    <Col md={6}>
                        <Table bordered>
                            <tbody>
                            {otherFiles.map((file, index) => (
                                <tr key={index}>
                                    <td>
                                    <FileElement fileName={file} filePath={path} />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}

        </>
    )
}

export default FileListComponent;