import {Button, Card } from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import StandardTable from "../../../../components/StandardTable.js";

const CarModelsTable = ({
    carModels,
    showTable
                        }) => {
    const headers = ['№', 'Име', 'Производител', 'Синоними', ''];

    return (
        <Card>
            {showTable() ? (
                <StandardTable headers={headers}>
                    {carModels.map((carModel) => (
                        <tr key={carModel.id}>
                            <td className={'col-1'}>{carModel.id}</td>
                            <td className={'col-2'}><Link to={`/settings/car-models/${carModel.id}`}>{carModel.name}</Link></td>
                            <td className={'col-2'}>{carModel.carManufacturer.name}</td>
                            <td className={'col-5 d-none d-md-table-cell'}>{carModel.synonyms}</td>
                            <td className={'col-2 d-none d-md-table-cell'}>
                                <Button variant="link">
                                    <Link to={`/settings/car-models/${carModel.id}`}>Преглед</Link>
                                </Button>
                                <Button variant="link">
                                    <Link to={`/settings/car-models/${carModel.id}/edit`}>Редактирай</Link>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default CarModelsTable