import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    successMessage: null,
    errorMessage: null,
    browserTabTitle: 'Сервиз Дървеница'
};

const informationMessageSlice = createSlice({
    name: 'informationMessages',
    initialState: initialState,
    reducers: {
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        resetSuccessMessage: (state) => {
            state.successMessage = null;
        },
        setBrowserTabTitle: (state, action) => {
            state.browserTabTitle = `${action.payload} - ${initialState.browserTabTitle}`;
        },
        resetBrowserTabTitle: (state) => {
            state.browserTabTitle = initialState.browserTabTitle;
        },
    },

});

export const {
    setSuccessMessage,
    resetSuccessMessage,
    setBrowserTabTitle,
    resetBrowserTabTitle
} = informationMessageSlice.actions;

export default informationMessageSlice.reducer;