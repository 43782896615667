import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
};
export const fetchAppSettings = fetchItems('appSettings/fetchAppSettings', '/app-settings');

const appSettingsSlice = createSlice({
    name: 'appSettings',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer,
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchAppSettings);
    },
});

export const {
    setCurrentPage,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;