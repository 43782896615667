import {Link} from "react-router-dom";

const TableLinkIconButton = (
    {
        to,
        title,
        children
    }) => {

    return (
        <Link className={'me-2 fs-4'} to={to} title={title}>{children}</Link>
    )

}

export default TableLinkIconButton;
