import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {resetSuccessMessage} from "../slices/informationMessagesSlice.js";
import './MessageDisplayComponent.css';

const MessageDisplayComponent = () => {
    const dispatch = useDispatch();
    const successMessage = useSelector((state) => state.informationMessages.successMessage);

    useEffect(() => {
        if (successMessage) {
            // Reset the message after 5 seconds
            const timeout = setTimeout(() => {
                dispatch(resetSuccessMessage());
            }, 5000);

            // Cleanup the timeout
            return () => clearTimeout(timeout);
        }
    }, [successMessage, dispatch]);

    return (
        <div className={'header-message-display'}>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
        </div>
    );
};

export default MessageDisplayComponent;
