import DateInput from "../../../../../components/Elements/DateInput.js";
import React from "react";

const EndDateInput = (
    {
        endDate,
        onAttributeChange,
        errors
    }
) => {

    return (
        <DateInput label={'Дата издаване'} dateValue={endDate} name={'endDate'}
                   onDateValueChange={onAttributeChange} errors={errors}/>

    )
}

export default EndDateInput;