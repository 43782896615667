export const getCountries = () => {
  const countries = [
    {"code": "93", "name": "Афганистан"},
    {"code": "355", "name": "Албания"},
    {"code": "213", "name": "Алжир"},
    {"code": "1684", "name": "Американска Самоа"},
    {"code": "376", "name": "Андора"},
    {"code": "244", "name": "Ангола"},
    {"code": "1264", "name": "Ангила"},
    {"code": "672", "name": "Антарктида"},
    {"code": "1268", "name": "Антигуа и Барбуда"},
    {"code": "54", "name": "Аржентина"},
    {"code": "374", "name": "Армения"},
    {"code": "297", "name": "Аруба"},
    {"code": "61", "name": "Австралия"},
    {"code": "43", "name": "Австрия"},
    {"code": "994", "name": "Азербайджан"},
    {"code": "1242", "name": "Бахами"},
    {"code": "973", "name": "Бахрейн"},
    {"code": "880", "name": "Бангладеш"},
    {"code": "1246", "name": "Барбадос"},
    {"code": "375", "name": "Беларус"},
    {"code": "32", "name": "Белгия"},
    {"code": "501", "name": "Белиз"},
    {"code": "229", "name": "Бенин"},
    {"code": "1441", "name": "Бермудски острови"},
    {"code": "975", "name": "Бутан"},
    {"code": "591", "name": "Боливия"},
    {"code": "387", "name": "Босна и Херцеговина"},
    {"code": "267", "name": "Ботсвана"},
    {"code": "55", "name": "Бразилия"},
    {"code": "246", "name": "Британска територия в Индийския океан"},
    {"code": "1284", "name": "Британски Вирджински острови"},
    {"code": "673", "name": "Бруней"},
    {"code": "359", "name": "България"},
    {"code": "226", "name": "Буркина Фасо"},
    {"code": "257", "name": "Бурунди"},
    {"code": "855", "name": "Камбоджа"},
    {"code": "237", "name": "Камерун"},
    {"code": "1", "name": "Канада"},
    {"code": "238", "name": "Кабо Верде"},
    {"code": "1345", "name": "Кайманови острови"},
    {"code": "236", "name": "Централноафриканска република"},
    {"code": "235", "name": "Чад"},
    {"code": "56", "name": "Чили"},
    {"code": "86", "name": "Китай"},
    {"code": "57", "name": "Колумбия"},
    {"code": "269", "name": "Коморски острови"},
    {"code": "682", "name": "Острови Кук"},
    {"code": "506", "name": "Коста Рика"},
    {"code": "385", "name": "Хърватия"},
    {"code": "53", "name": "Куба"},
    {"code": "357", "name": "Кипър"},
    {"code": "420", "name": "Чехия"},
    {"code": "243", "name": "Демократична република Конго"},
    {"code": "45", "name": "Дания"},
    {"code": "253", "name": "Джибути"},
    {"code": "1767", "name": "Доминика"},
    {"code": "1809", "name": "Доминиканска република"},
    {"code": "593", "name": "Еквадор"},
    {"code": "20", "name": "Египет"},
    {"code": "503", "name": "Ел Салвадор"},
    {"code": "240", "name": "Екваториална Гвинея"},
    {"code": "291", "name": "Еритрея"},
    {"code": "372", "name": "Естония"},
    {"code": "251", "name": "Етиопия"},
    {"code": "500", "name": "Фолклендски острови"},
    {"code": "298", "name": "Фарьорски острови"},
    {"code": "679", "name": "Фиджи"},
    {"code": "358", "name": "Финландия"},
    {"code": "33", "name": "Франция"},
    {"code": "689", "name": "Френска Полинезия"},
    {"code": "241", "name": "Габон"},
    {"code": "220", "name": "Гамбия"},
    {"code": "995", "name": "Грузия"},
    {"code": "49", "name": "Германия"},
    {"code": "233", "name": "Гана"},
    {"code": "350", "name": "Гибралтар"},
    {"code": "30", "name": "Гърция"},
    {"code": "299", "name": "Гренландия"},
    {"code": "1473", "name": "Гренада"},
    {"code": "1671", "name": "Гуам"},
    {"code": "502", "name": "Гватемала"},
    {"code": "224", "name": "Гвинея"},
    {"code": "245", "name": "Гвинея-Бисау"},
    {"code": "592", "name": "Гвиана"},
    {"code": "509", "name": "Хаити"},
    {"code": "504", "name": "Хондурас"},
    {"code": "852", "name": "Хонконг"},
    {"code": "36", "name": "Унгария"},
    {"code": "354", "name": "Исландия"},
    {"code": "91", "name": "Индия"},
    {"code": "62", "name": "Индонезия"},
    {"code": "98", "name": "Иран"},
    {"code": "964", "name": "Ирак"},
    {"code": "353", "name": "Ирландия"},
    {"code": "972", "name": "Израел"},
    {"code": "39", "name": "Италия"},
    {"code": "225", "name": "Кот д'Ивоар"},
    {"code": "1876", "name": "Ямайка"},
    {"code": "81", "name": "Япония"},
    {"code": "962", "name": "Йордания"},
    {"code": "7", "name": "Казахстан"},
    {"code": "254", "name": "Кения"},
    {"code": "686", "name": "Кирибати"},
    {"code": "850", "name": "Северна Корея"},
    {"code": "82", "name": "Южна Корея"},
    {"code": "965", "name": "Кувейт"},
    {"code": "996", "name": "Киргизстан"},
    {"code": "856", "name": "Лаос"},
    {"code": "371", "name": "Латвия"},
    {"code": "961", "name": "Ливан"},
    {"code": "266", "name": "Лесото"},
    {"code": "231", "name": "Либерия"},
    {"code": "218", "name": "Либия"},
    {"code": "423", "name": "Лихтенщайн"},
    {"code": "370", "name": "Литва"},
    {"code": "352", "name": "Люксембург"},
    {"code": "853", "name": "Макао"},
    {"code": "389", "name": "Северна Македония"},
    {"code": "261", "name": "Мадагаскар"},
    {"code": "265", "name": "Малави"},
    {"code": "60", "name": "Малайзия"},
    {"code": "960", "name": "Малдиви"},
    {"code": "223", "name": "Мали"},
    {"code": "356", "name": "Малта"},
    {"code": "692", "name": "Маршалови острови"},
    {"code": "222", "name": "Мавритания"},
    {"code": "230", "name": "Мавриций"},
    {"code": "262", "name": "Майот, Реюнион"},
    {"code": "52", "name": "Мексико"},
    {"code": "691", "name": "Микронезия"},
    {"code": "373", "name": "Молдова"},
    {"code": "377", "name": "Монако"},
    {"code": "976", "name": "Монголия"},
    {"code": "382", "name": "Черна гора"},
    {"code": "1664", "name": "Монтсерат"},
    {"code": "212", "name": "Мароко"},
    {"code": "258", "name": "Мозамбик"},
    {"code": "95", "name": "Мианмар"},
    {"code": "264", "name": "Намибия"},
    {"code": "674", "name": "Науру"},
    {"code": "977", "name": "Непал"},
    {"code": "31", "name": "Нидерландия"},
    {"code": "687", "name": "Нова Каледония"},
    {"code": "64", "name": "Нова Зеландия"},
    {"code": "505", "name": "Никарагуа"},
    {"code": "227", "name": "Нигер"},
    {"code": "234", "name": "Нигерия"},
    {"code": "683", "name": "Ниуе"},
    {"code": "1670", "name": "Северни Мариански острови"},
    {"code": "47", "name": "Норвегия"},
    {"code": "968", "name": "Оман"},
    {"code": "92", "name": "Пакистан"},
    {"code": "680", "name": "Палау"},
    {"code": "507", "name": "Панама"},
    {"code": "675", "name": "Папуа Нова Гвинея"},
    {"code": "595", "name": "Парагвай"},
    {"code": "51", "name": "Перу"},
    {"code": "63", "name": "Филипини"},
    {"code": "48", "name": "Полша"},
    {"code": "351", "name": "Португалия"},
    {"code": "974", "name": "Катар"},
    {"code": "40", "name": "Румъния"},
    {"code": "7", "name": "Русия"},
    {"code": "250", "name": "Руанда"},
    {"code": "290", "name": "Света Елена"},
    {"code": "1869", "name": "Сейнт Китс и Невис"},
    {"code": "1758", "name": "Сейнт Лусия"},
    {"code": "508", "name": "Сен Пиер и Микелон"},
    {"code": "1784", "name": "Сейнт Винсент и Гренадини"},
    {"code": "685", "name": "Самоа"},
    {"code": "378", "name": "Сан Марино"},
    {"code": "239", "name": "Сао Томе и Принсипи"},
    {"code": "966", "name": "Саудитска Арабия"},
    {"code": "221", "name": "Сенегал"},
    {"code": "381", "name": "Сърбия"},
    {"code": "248", "name": "Сейшелски острови"},
    {"code": "232", "name": "Сиера Леоне"},
    {"code": "65", "name": "Сингапур"},
    {"code": "421", "name": "Словакия"},
    {"code": "386", "name": "Словения"},
    {"code": "677", "name": "Соломонови острови"},
    {"code": "252", "name": "Сомалия"},
    {"code": "27", "name": "Южна Африка"},
    {"code": "211", "name": "Южен Судан"},
    {"code": "34", "name": "Испания"},
    {"code": "94", "name": "Шри Ланка"},
    {"code": "249", "name": "Судан"},
    {"code": "597", "name": "Суринам"},
    {"code": "47", "name": "Шпицберген и Ян Майен"},
    {"code": "268", "name": "Свазиленд"},
    {"code": "46", "name": "Швеция"},
    {"code": "41", "name": "Швейцария"},
    {"code": "963", "name": "Сирия"},
    {"code": "886", "name": "Тайван"},
    {"code": "992", "name": "Таджикистан"},
    {"code": "255", "name": "Танзания"},
    {"code": "66", "name": "Тайланд"},
    {"code": "670", "name": "Тимор-Лесте"},
    {"code": "228", "name": "Того"},
    {"code": "690", "name": "Токелау"},
    {"code": "676", "name": "Тонга"},
    {"code": "1868", "name": "Тринидад и Тобаго"},
    {"code": "216", "name": "Тунис"},
    {"code": "90", "name": "Турция"},
    {"code": "993", "name": "Туркменистан"},
    {"code": "1649", "name": "Търкс и Кайкос"},
    {"code": "688", "name": "Тувалу"},
    {"code": "256", "name": "Уганда"},
    {"code": "380", "name": "Украйна"},
    {"code": "971", "name": "Обединени арабски емирства"},
    {"code": "44", "name": "Великобритания"},
    {"code": "1", "name": "САЩ"},
    {"code": "598", "name": "Уругвай"},
    {"code": "998", "name": "Узбекистан"},
    {"code": "678", "name": "Вануату"},
    {"code": "58", "name": "Венецуела"},
    {"code": "84", "name": "Виетнам"},
    {"code": "681", "name": "Уолис и Футуна"},
    {"code": "967", "name": "Йемен"},
    {"code": "260", "name": "Замбия"},
    {"code": "263", "name": "Зимбабве"}
  ];

  const mergedCountries = countries.reduce((acc, current) => {
    const existing = acc.find(item => item.code === current.code);
    if (existing) {
      existing.name += `, ${current.name}`;
    } else {
      acc.push(current);
    }
    return acc;
  }, []);

  return mergedCountries;
}
