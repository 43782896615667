import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import { changeAttributeReducer, resetStateDataReducer } from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    id: null,
    name: '',
    permissions: [],
    users: []
};

export const fetchRole = fetchItem('role/fetchRole', '/roles')
export const saveRole = saveItem('role/saveRole', '/roles');

const roleItem = createSlice({
    name: 'roleItem',
    initialState: {
        data: {
            initialState
        },
        loading: false,
        error: null
    },
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchRole, saveRole)
    },
});

export default roleItem.reducer;
export const {
    changeAttribute,
    resetStateData
} = roleItem.actions;