import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    errors: [],
    status: 'idle'
};
export const fetchArticleStocksChanges = fetchItems(
    'articleStocks/fetchArticleStockChanges',
    '/article-stocks/changes/[ID_PLACEHOLDER]'
);

const articleStockChangeListSlice = createSlice({
    name: 'articleStocks',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer,
        resetStateData: resetStateDataReducer(initialState),
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchArticleStocksChanges)
    },
});

export const {
    setCurrentPage,
    resetStateData
} = articleStockChangeListSlice.actions;

export default articleStockChangeListSlice.reducer;