import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    errors: []
};
export const fetchArticles = fetchItems('articles/fetchArticles', '/articles');
export const fetchPaintArticles = fetchItems('articles/fetchPaintArticles', '/articles/paint-articles');
export const fetchCarDetailArticles = fetchItems('articles/fetchCarDetailArticles', '/articles/car-details');

const articleListSlice = createSlice({
    name: 'articles',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer,
        resetStateData: resetStateDataReducer(initialState),
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchArticles)
        getCommonExtraReducersList(builder, fetchPaintArticles)
        getCommonExtraReducersList(builder, fetchCarDetailArticles)
    },
});

export const {
    setCurrentPage,
    resetStateData
} = articleListSlice.actions;

export default articleListSlice.reducer;