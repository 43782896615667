import React from 'react';
import { Form } from 'react-bootstrap';
import {v4 as uuidv4} from "uuid";

function FormNumericInput(
    {
        label = '',
        name,
        value,
        onChange,
        errors = {},
        disabled = false,
        helpText,
        notNull = false,
        fractionDigits = 2
    }) {

    const elementId = uuidv4();

    const handleChange = (e) => {
        let value = e.target.value;
        value = value.replace(",", ".");

        if (/^\d*\.?\d*$/.test(value)) {
            onChange(name, value); // Pass the raw string to onChange
        }
    }

    const handleBlur = () => {
        if (value) {
            let numericValue = parseFloat(value);
            let formattedNumericValue = parseFloat(numericValue.toFixed(fractionDigits));
            onChange(name, isNaN(formattedNumericValue) ? notNull ? 0 : null : formattedNumericValue);
        } else {
            onChange(name, notNull ? 0 : null);
        }
    };

    return (
        <Form.Group>
            {label && (
                <Form.Label className={'me-2'}>{label}</Form.Label>
            )}
            <Form.Control
                id={`edit-${elementId}`}
                name={`edit-${elementId}`}
                value={value === null ? '' : value}
                onChange={handleChange}
                onBlur={handleBlur} // Convert to numeric when user leaves the input
                isInvalid={!!errors[name]}
                disabled={disabled}
                autoComplete={`new-${name}`}
            />
            {helpText && (
                <Form.Text className="text-muted">
                    {helpText}
                </Form.Text>
            )}
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default FormNumericInput;
