import {Routes, Route} from "react-router-dom";
import OrderListPage from "../pages/Orders/List/OrderListPage.js";
import OrderEditPage from "../pages/Orders/Single/OrderEditPage.js";
import OrderViewPage from "../pages/Orders/Single/OrderViewPage.js";
import OrderFilesPage from "../pages/Orders/Single/OrderFilesPage.js";
import {hasPermission} from "../utils/permissions.js";
import SingleOrderWrapper from "../pages/Orders/SingleOrderWrapper.js";
import SummaryOrders from "../pages/Orders/Summary/SummaryOrders.js";

const OrderRoutes = (
    {
        user
    }) => (
    <Routes>
        <Route index element={<OrderListPage />} />
        <Route
            path={'summary/:summaryName'}
            element={<SummaryOrders />}
        />
        <Route
            path={':id'}
            element={<SingleOrderWrapper />}
        >
            <Route index element={<OrderViewPage />} />
            <Route path='edit' element={hasPermission(user, 'update-orders') ? <OrderEditPage /> : <OrderViewPage />}  />
            <Route path='files' element={hasPermission(user, 'update-orders') ? <OrderFilesPage /> : <OrderViewPage />}  />
        </Route>
    </Routes>
);

export default OrderRoutes;