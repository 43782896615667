import React from 'react';
import parse from 'html-react-parser';

function ViewField(
    {
        label,
        value,
        parseHtml = false
    }) {

    return (
        <div className={'view-field'}>
            <span>{label && (<b>{label}:</b>)} {parseHtml && value ? parse(value) : value}</span>
        </div>
        );
}

export default ViewField;
