import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import { changeAttributeReducer, resetStateDataReducer } from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        name: '',
        synonyms: ''
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchSupplier = fetchItem('supplier/fetchSupplier', '/suppliers')
export const saveSupplier = saveItem('supplier/saveSupplier', '/suppliers');

const supplierItem = createSlice({
    name: 'supplierItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchSupplier, saveSupplier)
    },
});

export default supplierItem.reducer;
export const {
    changeAttribute,
    resetStateData
} = supplierItem.actions;