import React from "react";
import { Table } from "react-bootstrap";

const StandardTable = ({ headers, children }) => {
    return (
        <Table striped bordered hover>
            <thead className={'text-center'}>
            <tr>
                {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                ))}
            </tr>
            </thead>
            <tbody>{children}</tbody>
        </Table>
    );
};

export default StandardTable;
