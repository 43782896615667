import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";

const SupplierNav = ({
                                id,
                                activeKey
                            }) => {

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="show">
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/settings/suppliers/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default SupplierNav;