import {Card} from "react-bootstrap";
import ErrorAlertComponent from "../ErrorAlertComponent.js";
import React, {useEffect} from "react";
import LoadingComponent from "../LoadingComponent.js";
import MultipleFileUploadComponent from "../Files/MultipleFileUploadComponent.js";
import FileListComponent from "../Files/FileListComponent.js";
import {useDispatch, useSelector} from "react-redux";
import {setBrowserTabTitle} from "../../slices/informationMessagesSlice.js";

const SingleFilesLayout = ({
    title,
    showPage,
    stateErrors,
    navComponent : NavComponent,
    navProps,
    item,
    files,
    itemsPath,
    onFilesUpdate
                    }) => {
    const dispatch = useDispatch();
    const {loading} = useSelector((state) => state.file);

    useEffect(() => {
        if (!loading) {
            onFilesUpdate();
        }
    }, [loading]);


    useEffect(() => {
        dispatch(setBrowserTabTitle(title));
    }, [title]);

    const id = item.id;

    return (
        <div>
            <h2 className={!showPage ? 'blurry-text' : ''}>{title}</h2>
            {NavComponent && <NavComponent {...navProps} />}
            <Card>
                {showPage ? (
                    <>
                        <Card.Header>
                            <MultipleFileUploadComponent
                                id={id}
                                itemsPath={itemsPath}
                            />
                        </Card.Header>
                        <Card.Body>
                            <ErrorAlertComponent error={stateErrors}/>
                            <FileListComponent files={files} itemsPath={itemsPath} id={id}
                                               />
                        </Card.Body>
                    </>
                ) : (
                    <LoadingComponent/>
                )}
            </Card>
        </div>
    )
};

export default SingleFilesLayout;