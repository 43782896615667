import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import {hasPermission} from "../../../../utils/permissions.js";
import {useSelector} from "react-redux";

const CarNav = (
    {
        id,
        activeKey
    }) => {
    const { user } = useSelector((state) => state.auth);


    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="show">
            {id !== 'create' && (
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'view'} to={`/cars/${id}`}>Преглед</Nav.Link>
                </Nav.Item>
            )}
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/cars/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
            {id !== 'create' && (
                <>
                    {hasPermission(user, 'get-orders') && (
                        <Nav.Item>
                            <Nav.Link as={Link} eventKey={'orders'} to={`/cars/${id}/orders`}>Поръчки</Nav.Link>
                        </Nav.Item>
                    )}
                    <Nav.Item>
                        <Nav.Link as={Link} eventKey={'files'} to={`/cars/${id}/files`}>Файлове</Nav.Link>
                    </Nav.Item>
                </>
            )}
        </Nav>
    )
}

export default CarNav;