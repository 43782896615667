import React, {useEffect} from 'react';
import apiClient from "../axios";
import {useDispatch, useSelector} from "react-redux";

const Logout = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        const logout = async () => {
            if (user) {
                await apiClient.post('/logout');
                localStorage.setItem('isAuthenticated', 'false');
                window.location.href = '/';
            }
        }
        logout();
    }, [dispatch, user]);

    return (<></>);
};

export default Logout;
