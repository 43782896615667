import {Button, Card } from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import StandardTable from "../../../../components/StandardTable.js";

const CarManufacturersTable = ({
    carManufacturers,
    showTable
                               }) => {
    const headers = ['№', 'Име', 'Синоними', ''];

    return (
        <Card>
            {showTable() ? (
                <StandardTable headers={headers}>
                    {carManufacturers.map((carManufacturer) => (
                        <tr key={carManufacturer.id}>
                            <td className={'col-1'}>{carManufacturer.id}</td>
                            <td className={'col-2'}>
                                <Link to={`/settings/car-manufacturers/${carManufacturer.id}`}>{carManufacturer.name}</Link>
                            </td>
                            <td className={'col-7 d-none d-md-table-cell'}>{carManufacturer.synonyms}</td>
                            <td className={'col-2 d-none d-md-table-cell'}>
                                <Button variant="link">
                                    <Link to={`/settings/car-manufacturers/${carManufacturer.id}`}>Преглед</Link>
                                </Button>
                                <Button variant="link">
                                    <Link to={`/settings/car-manufacturers/${carManufacturer.id}/edit`}>Редактирай</Link>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default CarManufacturersTable;