import React from "react";
import MessageDisplayComponent from "../components/MessageDisplayComponent.js";

const Header = () => {

    return (
        <>
            <div className="header bg-light">
                <h1>Сервиз Дървеница</h1>
                <MessageDisplayComponent />
            </div>
        </>
    )
}

export default Header;