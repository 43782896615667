import {useEffect, useState} from "react";

import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { bg } from "react-day-picker/locale";
const DatePicker = (
    {
        mode= 'range',
        preSelected,
        onSelect
    }) => {

    useEffect(() => {
        setSelected(preSelected);
    }, []);

    const [selected, setSelected] = useState();

    const handleSelect = (selected) => {
        setSelected(selected);
        onSelect(selected);
    }

    return (
        <DayPicker
            locale={bg}
            numberOfMonths={3}
            mode={mode}
            selected={selected}
            onSelect={handleSelect}
        />
    );
}

export default DatePicker;