import React, {  useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import {Form} from "react-bootstrap";

const TextEditor = (
    {
        value,
        onChange,
        name,
        label,
        helpText,
        errors,
        disabled = false
                    }) => {
    const editor = useRef(null);

    const config = useMemo(() => {
        return {
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            placeholder: 'Добави текст',
            height: '200px',
            buttons: [
                'italic',
                'bold',
                '---',
            ],
            disabled: disabled
        }
    });

    return (
        <Form.Group className={'align-items-center mb-2'}>
            {label && (
                <Form.Label className={'me-2'}>{label}</Form.Label>
            )}
        <JoditEditor
            ref={editor}
            value={value}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newValue => onChange(name, newValue)} // preferred to use only this option to update the content for performance reasons
            onChange={newValue => {}}
        />
            {helpText && (
                <Form.Text className="text-muted">
                    {helpText}
                </Form.Text>
            )}
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default TextEditor;