import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import {changeAttributeReducer, resetStateDataReducer, setStatusReducer} from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        name: '',
        sellingPrice: 0,
        manufacturer: '',
        isNew: 1,
        additionalInformation: '',
        skuNumber: '',
        oemNumber: '',
        quantityType: null,
        settings: {},
        articleStocks: []
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchArticle = fetchItem('article/fetchArticle', '/articles')
export const saveArticle = saveItem('article/saveArticle', '/articles');

const articleItem = createSlice({
    name: 'articleItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState),
        setStatus: setStatusReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchArticle, saveArticle)
    },
});

export default articleItem.reducer;
export const {
    changeAttribute,
    resetStateData,
    setStatus
} = articleItem.actions;