import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {getVatFloatBgn, toFloatBgn} from "../../../utils/functions.js";
import Row from "react-bootstrap/Row";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import ArticleNav from "./components/ArticleNav.js";
import Col from "react-bootstrap/Col";
import ViewField from "../../../components/Elements/ViewField.js";

const ArticleViewPage = () => {
    const {id} = useParams();
    const {data: article, loading} = useSelector((state) => state.article);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Артикул № ${id} - ${article.name}`);
    }, [article.id]);

    const showPage = () => {
        return !loading && article.id;
    }

    return (
        <SingleViewLayout
            title={title}
            navComponent={ArticleNav}
            navProps={{id: id, activeKey: 'view'}}
            showPage={showPage()}
        >
            <Row>
                <Col md={3}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Тип'} value={article.articleType?.name}/>
                        <ViewField label={'Име'} value={article.name}/>
                        <ViewField label={'Код'} value={article.skuNumber}/>
                        <ViewField label={'Код производител'} value={article.oemNumber}/>
                        <ViewField label={'Производител'} value={article.manufacturer}/>
                        <ViewField label={'Нов (не е втора ръка)'} value={article.isNew ? 'Да' : 'Не'}/>
                        <ViewField label={'Мерна единица'} value={article.quantityType?.name}/>
                        <ViewField label={'Цена'} value={`${toFloatBgn(article.sellingPrice)} (ДДС: ${getVatFloatBgn(article.sellingPrice)})`}/>
                    </Col>
                </Col>
                <Col md={12}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <h6>Допълнителна информация</h6>
                        <ViewField value={article.additionalInformation} parseHtml={true}/>
                    </Col>
                </Col>
            </Row>
        </SingleViewLayout>
    );
};

export default ArticleViewPage;
