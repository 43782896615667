import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";
import {Button} from "react-bootstrap";
import DatePicker from "../../../../components/Elements/DatePicker.js";
import {dateToYmdFormat, formatDateToBeautifulString} from "../../../../utils/dateFunctions.js";

const OrderTableFilters = (
    {
        onFilterChange,
        showContent,
        filterData
    }) => {
    const {data: orderStatuses} = useSelector((state) => state.orderStatusList);
    const [filters, setFilters] = useState(filterData);
    const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        onFilterChange(filters);
    }, [filters]);

    useEffect(() => {
        setFilters((prevFilters) => {
            const mergedFilters = {...prevFilters};

            Object.entries(filterData).forEach(([key, value]) => {
                if (value !== undefined && value !== '') {
                    mergedFilters[key] = value;
                }
            });

            return mergedFilters;
        });
    }, []);

    const handleOrderStatusChange = (name, isChecked) => {
        const orderStatusId = name.split('-')[1];

        setFilters((prevFilters) => {
            const orderStatusIdsString = prevFilters.orderStatusIds || '';
            const orderStatusIdsArray = orderStatusIdsString ? orderStatusIdsString.split(',') : [];

            let updatedOrderStatusIds;

            if (isChecked) {
                if (!orderStatusIdsArray.includes(orderStatusId)) {
                    updatedOrderStatusIds = [...orderStatusIdsArray, orderStatusId];
                } else {
                    updatedOrderStatusIds = orderStatusIdsArray;
                }
            } else {
                updatedOrderStatusIds = orderStatusIdsArray.filter((id) => id !== orderStatusId);
            }

            return {
                ...prevFilters,
                orderStatusIds: updatedOrderStatusIds.length > 0 ? updatedOrderStatusIds.join(',') : '',
            };
        });
    }

    const handleDatePickerSelect = (selected) => {
        setFilters((prevFilters) => {
            return {
                ...prevFilters,
                dateField: 'all',
                from: selected?.from ? dateToYmdFormat(new Date(selected.from)) : '',
                to: selected?.to ? dateToYmdFormat(new Date(selected.to)) : ''
            }
        })
    }

    return (
        <div className={'mb-3'}>
            <Row>
                <Col md={12} style={{visibility: showContent ? 'visible' : 'hidden'}}>
                    <Row className="d-inline-flex flex-wrap">
                        <Col md={"auto"}><b>Статус:</b></Col>
                        {orderStatuses.map((orderStatus) => (
                            <Col md={"auto"} key={`order-status-id-${orderStatus.id}`}>
                                <span>{filters.orderStatusIds?.split(',').includes(orderStatus.id.toString()) || false}</span>
                                <FormCheckbox
                                    className={'d-inline'}
                                    name={`orderStatusId-${orderStatus.id}`}
                                    label={orderStatus.name}
                                    isChecked={filters.orderStatusIds?.split(',').includes(orderStatus.id.toString()) || false}
                                    onCheckboxChange={handleOrderStatusChange}
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col md={6} className={'mt-2'}>
                    <div>
                        <b>Дати: </b>
                        <i>
                            {filters.from && <span>от </span>}:
                            {filters.from ? formatDateToBeautifulString(filters.from) : ''}
                            {filters.to && <span> до: </span>}
                            {filters.to ? formatDateToBeautifulString(filters.to) : ''}
                        </i>
                    </div>
                    <Button onClick={() => setShowDatePicker(!showDatePicker)} className={'small-button'}
                            variant={"success"}>
                        {showDatePicker ? 'Скрий' : 'Покажи'} календар
                    </Button>
                </Col>
                {showDatePicker && (
                    <DatePicker onSelect={handleDatePickerSelect} preSelected={{to: filters.to, from: filters.from}}/>
                )}

            </Row>
        </div>
    );
};

export default OrderTableFilters;