export const formatDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }
    return null;
};

export const dateToYmdFormat = (date) => {
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "Europe/Sofia" }));

    const year = utcDate.getFullYear();
    const month = String(utcDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(utcDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const formatDateToBeautifulString = (dateString) => {
    const date = new Date(dateString);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('bg-BG', options).format(date);
}

export const formatDateTimeToBeautifulString = (dateString) => {
    const date = new Date(dateString);

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };

    return new Intl.DateTimeFormat('bg-BG', options).format(date);
}