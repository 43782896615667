import React from 'react';
import { useSelector } from 'react-redux';
import {fetchOrders, resetStateData} from "../../../slices/orderListSlice.js";
import OrdersTable from "./components/OrdersTable.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import OrderTableFilters from "./components/OrderTableFilters.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const OrderListPage = () => {
    const {data: orders, loading, pagination} = useSelector((state) => state.orderList);

    return (
        <div>
            <ListHeaderLayout
                title={'Поръчки'}
                createLink={'/orders/create/edit'}
            />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchOrders}
                loading={loading}
                FiltersComponent={OrderTableFilters}
                TableComponent={OrdersTable}
                pagination={pagination}
                data={orders}
            />
        </div>
    );
};

export default OrderListPage;
