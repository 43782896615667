import { Card } from "react-bootstrap";
import {toFloatBgn} from "../../../../utils/functions.js";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {CardImage, CartCheckFill, EyeFill, PencilSquare, Truck} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import StandardTable from "../../../../components/StandardTable.js";

const ArticlesTable = ({showTable, data}) => {
    const headers = ['№', 'Име', 'Тип', 'Код', 'Прозиводител', 'Цена', ''];

    return (
        <Card>
            {showTable ? (
                <StandardTable headers={headers}>
                    {data.map((article) => (
                        <tr key={article.id}>
                            <td>{article.id}</td>
                            <td><Link to={`/articles/${article.id}`}>{article.name}</Link></td>
                            <td>{article.articleType.name}</td>
                            <td>{article.skuNumber}{!article.isNew ? <i> (втора ръка)</i> : ''}</td>
                            <td>{article.manufacturer}</td>
                            <td>{toFloatBgn(article.sellingPrice)}</td>
                            <td>
                                <TableLinkIconButton to={`/articles/${article.id}`} title={'Преглед'}><EyeFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/articles/${article.id}/edit`} title={'Редактирай'}><PencilSquare /></TableLinkIconButton>
                                <TableLinkIconButton to={`/articles/${article.id}/orders`} title={'Поръчки'}><CartCheckFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/articles/${article.id}/files`} title={'Файлове'}><CardImage /></TableLinkIconButton>
                                <TableLinkIconButton to={`/articles/${article.id}/stocks`} title={'Наличност'}><Truck /></TableLinkIconButton>
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>

            )}
        </Card>
    )
}

export default ArticlesTable;