import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Row from "react-bootstrap/Row";
import ClientNav from "./components/ClientNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import {useParams} from "react-router-dom";
import Col from "react-bootstrap/Col";
import ViewField from "../../../components/Elements/ViewField.js";

const ClientViewPage = () => {
    const {id} = useParams();
    const {data: client, loading} = useSelector((state) => state.client);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Клиент № ${id} - ${client.name}` );
    }, [client.id]);

    const showPage = () => {
        return !loading;
    }

    return (
        <SingleViewLayout
            title={title}
            navComponent={ClientNav}
            navProps={{id: id, activeKey: 'view'}}
            showPage={showPage()}
            >
            <Row>
                <Col md={3}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Име'} value={client.name} />
                    </Col>
                </Col>
                <Col md={3}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Тип клиент'} value={client.clientType?.name} />
                        <ViewField label={client.clientType?.label === 'person' ? 'ЕГН' : 'ЕИК'} value={client.identificationId} />
                        {client.clientType?.label === 'entity' ? (
                            <>
                            <ViewField label={'ДДС номер'} value={client.vatNumber} />
                            <ViewField label={'МОЛ'} value={client.molField} />
                            </>
                        ) : <></>}
                    </Col>
                </Col>
                <Col md={3}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Email'} value={client.email} />
                        <ViewField label={'Телефон'} value={client.phoneNumber} />
                        <ViewField label={'Адрес'} value={client.address} />
                        <ViewField label={'Град'} value={client.city} />
                        <ViewField label={'ПК'} value={client.zip} />
                        <ViewField label={'Държава'} value={client.country} />
                    </Col>
                </Col>
                <Col md={3}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Отстъпка'} value={`${client.discount}%`} />
                        <ViewField label={'Застраховател'} value={client.isInsurer ? 'Да' : 'Не'} />
                    </Col>
                </Col>
                <Col md={12}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <h6>Допълнителна информация</h6>
                        <ViewField value={client.additionalInformation} parseHtml={true} />
                    </Col>
                </Col>

            </Row>
        </SingleViewLayout>
    );
};

export default ClientViewPage;
