const HelpText = (
    {
        children,
        color = 'red'
    }) => {
    return (
        <i className={'help-text'} style={{color: color}}>{children}</i>
    );
};

export default HelpText;
