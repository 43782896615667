import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import {Link, useParams} from "react-router-dom";
import {toFloatBgn} from "../../../utils/functions.js";
import OrderNav from "./components/OrderNav.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDateTimeToBeautifulString, formatDateToBeautifulString} from "../../../utils/dateFunctions.js";
import OrderLinesTableComponent from "./components/OrderLinesTableComponent.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import ViewField from "../../../components/Elements/ViewField.js";
import {Table} from "react-bootstrap";
import DocumentPdf from "../Invoice/DocumentPdf.js";
import HelpText from "../../../components/Elements/HelpText.js";

const OrderViewPage = () => {
    const {id} = useParams();
    const {data: order, loading} = useSelector((state) => state.order);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Поръчка № ${id}`);
    }, [order.id]);

    const showPage = () => {
        return !loading;
    }

    return (
        <SingleViewLayout
            title={title}
            showPage={showPage()}
            navComponent={OrderNav}
            navProps={{id: id, activeKey: 'view'}}
        >
            <Row>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <h5>
                            <ViewField label={'Статус'} value={order.orderStatus?.name}/>
                        </h5>
                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Създадена'} value={formatDateToBeautifulString(order.createdAt)}/>
                    </div>
                </Col>
            </Row>
            <Row>

            </Row>
            <Row>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Дата приемане'}
                                   value={order.startDate ? formatDateToBeautifulString(order.startDate) : ''}/>
                        <ViewField label={'Дата издаване'}
                                   value={order.endDate ? formatDateToBeautifulString(order.endDate) : ''}/>

                        <ViewField label={'Клиент'} value={<Link to={`/clients/${order.client?.id}`}
                                                                 target={'_blank'}>{order.client?.name}</Link>}/>
                        <ViewField label={'Кола'} value={<Link to={`/cars/${order.car?.id}`}
                                                               target={'_blank'}>{order.car?.registrationNumber}</Link>}/>
                        <ViewField label={'Пробег'} value={order.mileage}/>
                        <ViewField label={'ЕИК/ЕГН'} value={order.client?.identificationId}/>
                        <ViewField label={'ДДС номер'} value={order.client?.vatNumber}/>
                        <ViewField label={'МОЛ'} value={order.client?.molField}/>
                        <ViewField label={'Телефон'} value={order.client?.phoneNumber}/>
                        <ViewField label={'Email'} value={order.client?.email}/>
                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Изпълнител'} value={order.partner?.name}/>
                        <ViewField label={'Застраховател'} value={<Link to={`/clients/${order.insurer?.clientId}`}
                                                                        target={'_blank'}>{order.insurer?.name}</Link>}/>
                        <ViewField label={'Номер щета'} value={order.insurerClaimId}/>

                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'С ФАКТУРА'} value={<b className={'text-uppercase'}>{order.hasInvoice ? 'ДА' : 'НЕ'}</b>} />
                        <ViewField label={'Дата фактура'}
                                   value={order.invoiceDate ? formatDateToBeautifulString(order.invoiceDate) : ''}/>
                        <ViewField label={'Номер фактура'} value={order.invoiceNumber}/>
                        <ViewField label={'Дата падеж'} value={order.paymentDueDate}/>
                        <ViewField label={'Клиент фактура'} value={<Link to={`/clients/${order.invoiceClient?.id}`}
                                                                         target={'_blank'}>{order.invoiceClient?.name}</Link>}/>
                        <ViewField label={'Начин плащане'} value={order.paymentType?.name}/>
                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Бележка'} value={order.additionalInformation} parseHtml={true}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className={'d-flex'}>
                        <DocumentPdf type={'proforma'} id={id} details={true}/>
                        <DocumentPdf type={'proforma'} id={id} details={false}/>
                {order.invoiceNumber &&
                        <DocumentPdf
                            type={'invoice'}
                            id={id}
                            details={false}
                            helpText={<span>При фактурирана поръчка, за да се генерира нова<br/> фактура е нужно да се изтрие старата от "Файлове"</span>}
                        />
                }
                    </div>

            </Row>
            <Row className={'mt-3'}>
                <h5>Позиции</h5>
                <OrderLinesTableComponent lines={order.orderLines} showingActiveLines={true} viewOnly={true}/>

                <div>
                    {order.hasInvoice &&
                        <>
                            <div><b>Данъчна основа:</b> {toFloatBgn(order.totalWithoutVat)}</div>
                            <div><b>ДДС:</b> {toFloatBgn(order.vatOfTotal)}</div>
                        </>
                    }
                    <div><b>Сума за плащане:</b> {toFloatBgn(order.total)}</div>
                    {!order.hasInvoice &&
                        <HelpText>Поръчка без фактура</HelpText>
                    }
                </div>
            </Row>

            <Row className={'mt-3'}>
                <h5>Коментари</h5>
                <div>
                    <Table bordered hover striped>
                        <thead>
                        <tr>
                            <th>Действие</th>
                            <th>Пояснение</th>
                            <th>Коментар</th>
                            <th>Потребител</th>
                            <th>Дата</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order.orderComments.map((comment, index) => (
                            <tr key={`order-comment-key-${index}`}>
                                <td>{comment.action}</td>
                                <td>{comment.actionDetail}</td>
                                <td>{comment.comment}</td>
                                <td>{comment.user.name}</td>
                                <td>{formatDateTimeToBeautifulString(comment.createdAt)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </Row>
        </SingleViewLayout>
    );
};

export default OrderViewPage;
