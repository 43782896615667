import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import {
    changeAttributeReducer,
    resetStateDataReducer,
    setDataReducer,
    setStatusReducer
} from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        article: null,
        supplier: null,
        costPrice: 0,
        stockQuantity: 0
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const getInitialArticleStockData = () => ({ ...initialState.data });
export const fetchArticleStock = fetchItem('articleStock/fetchArticleStock', '/article-stocks');

export const saveArticleStock = saveItem('articleStock/saveArticleStock', '/article-stocks');

const articleStockItem = createSlice({
    name: 'articleStockItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState),
        setData: setDataReducer,
        setStatus: setStatusReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchArticleStock, saveArticleStock)
    },
});

export default articleStockItem.reducer;
export const {
    changeAttribute,
    resetStateData,
    setData,
    setStatus
} = articleStockItem.actions;