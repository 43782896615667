export const getMimeTypeFromFileName = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();

    const mimeTypes = {
        pdf: 'application/pdf',
        png: 'image/png',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg'
    };

    if (mimeTypes[extension]) {
        return mimeTypes[extension];
    } else {
        throw new Error('Unsupported file type');
    }
}