import { createSlice} from '@reduxjs/toolkit';
import { fetchFileItems} from "../utils/sliceFunctions.js";
import {resetStateDataReducer} from "../utils/commonReducers.js";
import {getFilesFetchExtraReducerList} from "../utils/commonExtraReducers.js";

const initialState = {
    data: [],
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchFiles = fetchFileItems('files/fetch', 'files')

const fileListSlice = createSlice({
    name: 'files',
    initialState: initialState,
    reducers: {
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getFilesFetchExtraReducerList(builder, fetchFiles)
    },
});

export default fileListSlice.reducer;
export const {
    resetStateData
} = fileListSlice.actions;