import Button from "react-bootstrap/Button";
import OrderLinesTableComponent from "./OrderLinesTableComponent.js";
import OrderLineModalComponent from "./OrderLineModalComponent.js";
import React, {useState} from "react";
import {removeOrderLine, updateOrderLine} from "../../../../slices/orderSlice.js";
import {useDispatch} from "react-redux";
import {Card, Form} from "react-bootstrap";
import {toFloat} from "../../../../utils/functions.js";

const OrderLinesComponent = (
    {
        order,
        onLineUpdate,
        errors
    }) => {
    const dispatch = useDispatch();
    const [editLine, setEditLine] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const showEditOrderLineModal = (line) => {
        setEditLine(line);
        setShowModal(true);
    };

    const closeOrderLineModal = () => {
        setEditLine(null);
        setShowModal(false)
    }

    const showNewOrderLineModal = () => {
        setEditLine(null);
        setShowModal(true);
    }

    const handleLineUpdate = (orderLine) => {
        onLineUpdate(orderLine);
        closeOrderLineModal();
    }

    const handleRemoveOrderLine = (orderLine) => {
        if (isNaN(orderLine.id)) {
            dispatch(removeOrderLine(orderLine))
        } else {
            const inactiveOrderLine = {...orderLine};
            inactiveOrderLine.isActive = false;
            inactiveOrderLine.childLines = inactiveOrderLine.childLines.map((childLine) => ({
                ...childLine,
                isActive: false,
            }));

            dispatch(updateOrderLine(inactiveOrderLine));
        }
    }

    return (
        <Card className={'mt-3'}>
            <Card.Body>
                <Form.Control.Feedback type="invalid"
                                       style={errors['orderLines'] ? {display: 'block'} : {display: 'none'}}>
                    <h4>{errors['orderLines']}</h4>
                </Form.Control.Feedback>
                <h4>Позиции</h4>
                {order.client !== null ? (
                <Button variant="primary" onClick={showNewOrderLineModal}>Добави</Button>
                ) : (
                    <span>Добави клиент преди да добавиш позиция!</span>
                )}
                <OrderLinesTableComponent
                    lines={order.orderLines}
                    showingActiveLines={true} onEditLine={showEditOrderLineModal} onRemoveOrderLine={handleRemoveOrderLine}/>
                <h5>Стойност: {toFloat(order.total)}лв</h5>
                {showModal && (
                    <OrderLineModalComponent
                        order={order}
                        show={showModal}
                        orderLine={editLine}
                        onHide={closeOrderLineModal}
                        closeOrderLineModal={closeOrderLineModal}
                        onLineUpdate={handleLineUpdate}
                    />
                )}
            </Card.Body>
        </Card>
    )
}

export default OrderLinesComponent;