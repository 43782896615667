import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchUsers, resetStateData} from "../../../slices/userListSlice.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import UsersTable from "./components/UsersTable.js";
import PaginationComponent from "../../../components/PaginationComponent.js";

const UserListPage = () => {
    const dispatch = useDispatch();
    const {data: users, loading, pagination} = useSelector((state) => state.userList);
    const [searchParams, setSearchParams] = useState({page: 1, search: '', filters: {}})

    useEffect(() => {
        dispatch(resetStateData());
    }, []);

    useEffect(() => {
        dispatch(fetchUsers(searchParams));
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams({
            page: page,
            filters: searchParams.filters,
            search: searchParams.search
        })
    };

    const handleQueryChange = (query) => {
        setSearchParams({
            page: 1,
            filters: searchParams.filters,
            search: query
        })
    }

    const showTable = () => {
        return !loading;
    }

    return (
        <div>
            <ListHeaderLayout
                title={'Потребители'}
                createLink={'/settings/users/create/edit'}
                onQueryChange={handleQueryChange}
            />
            <UsersTable showTable={() => showTable()} users={users} />

            <PaginationComponent
                onPageChange={handlePageChange}
                pagination={pagination}
            />
        </div>
    );
};

export default UserListPage;
