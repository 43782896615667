import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
}

export const fetchDamageLevels = fetchItems(
    'damageLevels/fetchDamageLevels',
    '/damage-levels'
);

const damageLevelListSlice = createSlice({
    name: 'damageLevels',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchDamageLevels)
    },
});

export default damageLevelListSlice.reducer;