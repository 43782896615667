import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchCarManufacturers, resetStateData} from '../../../slices/carManufacturerListSlice.js';
import PaginationComponent from "../../../components/PaginationComponent.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import CarManufacturersTable from "./components/CarManufacturersTable.js";

const CarManufacturerListPage = () => {
    const dispatch = useDispatch();
    const {data: carManufacturers, loading, pagination} = useSelector((state) => state.carManufacturerList);
    const [searchParams, setSearchParams] = useState({page: 1, search: '', filters: {}})

    useEffect(() => {
        dispatch(resetStateData());
    }, []);

    useEffect(() => {
        dispatch(fetchCarManufacturers(searchParams));
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams({
            page: page,
            filters: searchParams.filters,
            search: searchParams.search
        })
    };

    const handleQueryChange = (query) => {
        setSearchParams({
            page: 1,
            filters: searchParams.filters,
            search: query
        })
    }

    const showTable = () => {
        return !loading;
    }

    return (
        <div>
            <ListHeaderLayout
                title={'Производители коли'}
                createLink={'/settings/car-manufacturers/create/edit'}
                onQueryChange={handleQueryChange}
            />
            <CarManufacturersTable
                carManufacturers={carManufacturers}
                showTable={() => showTable()}
            />
            <PaginationComponent
                onPageChange={handlePageChange}
                pagination={pagination}
            />
        </div>
    );
};

export default CarManufacturerListPage;
