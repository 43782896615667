import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchCarModels, resetStateData} from '../../../slices/carModelListSlice.js';
import PaginationComponent from "../../../components/PaginationComponent.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import CarModelsTable from "./components/CarModelsTable.js";

const CarModelListPage = () => {
    const dispatch = useDispatch();
    const {data: carModels, loading, pagination} = useSelector((state) => state.carModelList);
    const [searchParams, setSearchParams] = useState({page: 1, search: '', filters: {}})

    useEffect(() => {
        dispatch(resetStateData());
    }, []);

    useEffect(() => {
        dispatch(fetchCarModels(searchParams));
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams({
            page: page,
            filters: searchParams.filters,
            search: searchParams.search
        })
    };

    const handleQueryChange = (query) => {
        setSearchParams({
            page: 1,
            filters: searchParams.filters,
            search: query
        })
    }

    const showTable = () => {
        return !loading;
    }

    return (
        <div>
            <ListHeaderLayout
                title={'Модели коли'}
                createLink={'/settings/car-models/create/edit'}
                onQueryChange={handleQueryChange}
                />
            <CarModelsTable carModels={carModels} showTable={() => showTable()}/>
            <PaginationComponent
                onPageChange={handlePageChange}
                pagination={pagination}
            />

        </div>
    );
};

export default CarModelListPage;
