import React from 'react';
import { Form } from 'react-bootstrap';

const FormCheckbox = (
    {
        label,
        isChecked,
        onCheckboxChange,
        name,
        disabled = false,
        className = ''
    }) => {

    const handleCheckboxChange = ({target: {name}}) => {
        onCheckboxChange(name, !isChecked)
    }

    return (
        <Form.Group className={className}>
            <Form.Check
                id={'checkbox-' + name}
                name={name}
                type="checkbox"
                label={label}
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={disabled}
            />
        </Form.Group>
    );
};

export default FormCheckbox;
