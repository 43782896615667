import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import FormInput from "../../../../components/Elements/FormInput.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import {getArticleStockChangeSchema} from "../../../../utils/schemas.js";
import {setErrorsFromValidation} from "../../../../utils/validation.js";

const ChangeArticleStockQuantityModal = (
    {
        article,
        articleStock,
        isIncrease,
        onClose,
        onUpdate
    }
) => {
    const [quantity, setQuantity] = useState(0);
    const [note, setNote] = useState('');
    const [errors, setErrors] = useState({});
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [quantityAfterChange, setQuantityAfterChange] = useState(null);

    useEffect(() => {
        setQuantityAfterChange(articleStock.stockQuantity);
    }, []);

    const changeQuantity = (name, value) => {
        setQuantity(value);
        const floatValue = parseFloat(value);
        setQuantityAfterChange(isIncrease ? articleStock.stockQuantity + floatValue : articleStock.stockQuantity - floatValue);
    }

    const changeNote = (name, value) => {
        setNote(value);
    }

    const handleUpdate = async  () => {
        setSaveButtonDisabled(true);
        setErrors({});
        try {
            await getArticleStockChangeSchema().validate({quantity: quantity, note: note}, {abortEarly: false});
            onUpdate(
                articleStock,
                isIncrease ? quantity : -1 * quantity,
                note
            );
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
            setSaveButtonDisabled(false);
        }
    }

    return (
        <Modal show={true} onHide={onClose} animation={false} size="lg">
            <Modal.Header closeButton>
                <h5><b>{isIncrease ? 'Добави' : 'Намали'}</b> наличност</h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={3}>
                        <h6 className={'d-block'}>{articleStock.supplier.name}</h6>
                        <span className={'d-block'}>Мерна единица: {article?.quantityType.name}</span>
                        <FormNumericInput name={'quantity'} label={isIncrease ? 'Добави' : 'Намали'} value={quantity} onChange={changeQuantity} errors={errors} />
                    </Col>
                </Row>
                <Row>
                    <span>Наличност <b>преди</b> промяна: {articleStock.stockQuantity}</span>
                    <span>Наличност <b>след</b> промяна: {quantityAfterChange}</span>
                </Row>
                <Row>
                    <Col md={12}>
                    <FormInput as={'textarea'} name={'note'} value={note} onChange={changeNote} label={'Бележка'} errors={errors} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>Откажи</Button>
                <Button variant="success" disabled={saveButtonDisabled} onClick={handleUpdate}>Запази</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default ChangeArticleStockQuantityModal;