import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {getOrderLineSchema, getPaintOrderLineSchema} from "../../../../utils/schemas.js";
import {setErrorsFromValidation} from "../../../../utils/validation.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import {fetchOrderLineTypes} from "../../../../slices/orderLineTypeListSlice.js";
import EditOrderLineComponent from "./EditOrderLineComponent.js";
import EditOrderLinePaintComponent from "./EditOrderLinePaintComponent.js";
import {v4 as uuidv4} from "uuid";

const OrderLineModalComponent = ({
    order,
    orderLine = null,
    closeOrderLineModal,
    onLineUpdate,
}) => {
    const dispatch = useDispatch();
    const [editOrderLine, setEditOrderLine] = useState(null);
    const [orderLineType, setOrderLineType] = useState(null);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    const [errors, setErrors] = useState({});
    const {data: orderLineTypes, loading: orderLineTypesLoading} = useSelector(
        (state) => state.orderLineTypeList
    )

    useEffect(() => {
        if (!orderLineTypes || orderLineTypes.length === 0) {
            dispatch(fetchOrderLineTypes());
        }
        if (orderLineType) {
            setOrderLineType(null)
        }
    }, []);

    useEffect(() => {
        if (orderLine) {
            setEditOrderLine(orderLine);
            setOrderLineType(orderLine.orderLineType)
        } else {
            resetOrderLine();
        }
    }, [])

    useEffect(() => {
        if (!orderLine) {
            resetOrderLine();
        }
    }, [orderLineType])

    const resetOrderLine = () => {
        setEditOrderLine({
            id: uuidv4(),
            article: null,
            price: 0,
            quantity: 0,
            totalPrice: 0,
            childLines: [],
            inactiveChildLines: [],
            useStock: null,
            articleStock: null,
            costPrice: 0,
            supplier: null,
            additionalInformation: null,
            isActive: true,
            discount: orderLineType?.isCost ? 0 : order.invoiceClient?.discount,
            orderLineType: orderLineType,
            paintCode: orderLineType?.isPaintPdr
                ? order.car?.paintCode
                : null
        });
    }

    const handleClose = () => {
        closeOrderLineModal();
    };

    const changeAttribute = (attribute, value) => {
        setEditOrderLine((prevOrderLine) => ({
            ...prevOrderLine,
            [attribute]: value,
        }));

    };

    const handleLineUpdate = async () => {
        try {
            setSaveButtonDisabled(true);
            setErrors({});
            const schema = editOrderLine.orderLineType.isPaintPdr ? getPaintOrderLineSchema(editOrderLine) : getOrderLineSchema(editOrderLine);
            await schema.validate(editOrderLine, {abortEarly: false});
            onLineUpdate(editOrderLine);
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
            setSaveButtonDisabled(false);
        }
    };

    const handleArticleChange = async (name, article) => {
        const discount = order.invoiceClient?.discount && !orderLineType.isCost ? order.invoiceClient?.discount : 0;
        changeAttribute('article', article);
        changeAttribute('price', article.sellingPrice);
        changeAttribute('quantity', 1);
        changeAttribute('discount', discount);
        if (article.articleType.hasStock && article.articleStocks.length === 0) {
            changeAttribute('useStock', 0);
        }
        if (discount) {
            changeAttribute('totalPrice', getLineTotalPrice(article.sellingPrice, 1, discount));
        } else {
            changeAttribute('totalPrice', article.sellingPrice);
        }
    }

    const handlePriceRelatedChange = (name, value) => {
        if (editOrderLine.article) {
            handlePriceChange(name, value);
        }
        changeAttribute(name, value);
    }

    const handlePriceChange = (changedAttribute, value) => {
        const price = changedAttribute === 'price' ? value : editOrderLine.price;
        const quantity = changedAttribute === 'quantity' ? value : editOrderLine.quantity;
        const discount = changedAttribute === 'discount' ? value : editOrderLine.discount;
        changeAttribute('totalPrice', getLineTotalPrice(price, quantity, discount));
    }

    const getLineTotalPrice = (price, quantity, discount) => {
        let result = price * quantity * ((100 - discount) / 100);
        return parseFloat(result.toFixed(2));
    }

    const getSearchArticleTypeLabel = () => {
        if (editOrderLine.orderLineType?.isRepair) {
            return ['product', 'service'];
        } else if (editOrderLine.orderLineType?.isSale) {
            return 'product'
        } else if (editOrderLine.orderLineType.isCost) {
            return ['product', 'service', 'wash'];
        }
        return editOrderLine.orderLineType?.label;
    }

    return (
        <Modal show={true} onHide={handleClose} animation={false} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Позиция</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {(editOrderLine && !orderLineTypesLoading) && (
                    <Form>
                        <Form.Group className={'mb-3'}>
                            <SelectInput
                                name={'orderLineType'}
                                onChange={(name, item) => {
                                    changeAttribute('orderLineType', item);
                                    setOrderLineType(item)
                                }}
                                selectOptions={orderLineTypes}
                                value={orderLineType ? orderLineType.id : ''}
                                disabled={orderLine !== null}
                            />
                        </Form.Group>
                        {editOrderLine.orderLineType?.isPaintPdr ?
                            <EditOrderLinePaintComponent
                                onArticleChange={changeAttribute}
                                editOrderLine={editOrderLine}
                                errors={errors}
                                onPriceRelatedChange={handlePriceRelatedChange}
                                onAttributeChange={changeAttribute}
                            />

                            : editOrderLine.orderLineType !== null ?
                                <EditOrderLineComponent
                                    onArticleChange={handleArticleChange}
                                    editOrderLine={editOrderLine}
                                    errors={errors}
                                    onPriceRelatedChange={handlePriceRelatedChange}
                                    onAttributeChange={changeAttribute}
                                    articleTypeLabel={getSearchArticleTypeLabel()}
                                />
                                : <></>
                        }
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>Откажи</Button>
                {editOrderLine && editOrderLine.article &&
                    <Button variant="success" disabled={saveButtonDisabled} onClick={handleLineUpdate}>Запази</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default OrderLineModalComponent;