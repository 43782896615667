import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const ArticleTableFilters = (
    {
        onFilterChange,
        showContent,
        filterData
    }) => {
    const {data: articleTypes} = useSelector((state) => state.articleTypeList);
    const [filters, setFilters] = useState(filterData);

    useEffect(() => {
        onFilterChange(filters);
    }, [filters]);

    useEffect(() => {
        setFilters((prevFilters) => {
            const mergedFilters = {...prevFilters};

            Object.entries(filterData).forEach(([key, value]) => {
                if (value !== undefined && value !== '') {
                    mergedFilters[key] = value;
                }
            });

            return mergedFilters;
        });
    }, []);


    const handleArticleTypeFilterChange = (name, value) => {
        setFilters((prevFilters) => {
            return {
                ...prevFilters,
                articleTypeId: value ? value.id : '',
            };
        });
    }

    return (
        <div className={'mb-3'}>
            <Row>
                <Col md={3} style={{visibility: showContent ? 'visible' : 'hidden'}}>
                    <SelectInput
                        hasAllOption={true}
                        label="Артикул тип"
                        name="articleType"
                        selectOptions={articleTypes.filter(type => !type.isSettingValue)}
                        value={filters.articleTypeId || ''}
                        onChange={handleArticleTypeFilterChange}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ArticleTableFilters;