import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    errors: []
}
export const fetchCars = fetchItems('cars/fetchCars', '/cars');

export const fetchClientCars = fetchItems(
    'cars/fetchClientCars', '/cars/client/[ID_PLACEHOLDER]'
);

const carListSlice = createSlice({
    name: 'cars',
    initialState: initialState,
    reducers: {
        resetStateData: resetStateDataReducer(initialState),
        setCurrentPage: setCurrentPageReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchCars)
        getCommonExtraReducersList(builder, fetchClientCars)
    },
});

export const {
    setCurrentPage,
    resetStateData
} = carListSlice.actions;

export default carListSlice.reducer;