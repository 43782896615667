import { createSlice } from '@reduxjs/toolkit';
import {saveItemsBatch} from "../utils/sliceFunctions.js";
import {getSaveCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {resetStateDataReducer, setCurrentPageReducer, setStatusReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    errors: [],
    status: 'idle'
};
export const saveArticleStocksBatch = saveItemsBatch(
    'articleStocks/saveArticleStocks',
    '/article-stocks/batch'
);

const articleStockListSlice = createSlice({
    name: 'articleStocks',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer,
        resetStateData: resetStateDataReducer(initialState),
        setStatus: setStatusReducer
    },
    extraReducers: (builder) => {
        getSaveCommonExtraReducersList(builder, saveArticleStocksBatch)
    },
});

export const {
    setCurrentPage,
    resetStateData,
    setStatus
} = articleStockListSlice.actions;

export default articleStockListSlice.reducer;