import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchCarManufacturer, resetStateData, saveCarManufacturer} from "../../../slices/carManufacturerSlice.js";
import {processIdInformation, processSubmit} from "../../../utils/functions.js";
import CarManufacturerForm from "./components/CarManufacturerForm.js";
import {getValidationSchema} from "../../../utils/validation.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import CarManufacturerNav from "./components/CarManufacturerNav.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";

const CarManufacturerEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: carManufacturer, loading, errors: stateErrors, status} = useSelector((state) => state.carManufacturer);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const carManufacturerSchema = getValidationSchema({
        name: ['required', ['max', 50], ['min', 2]],
        synonyms: [['max', 255]]
    })

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCarManufacturer({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(setSuccessMessage(`Успешно запазен прозиводител ${carManufacturer.name}`));
            dispatch(resetStateData());
            navigate('/settings/car-manufacturers');
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, carManufacturerSchema, saveCarManufacturer, dispatch, carManufacturer);
    };

    const showForm = () => {
        return !loading;
    }

    const getTitle = () => {
        return idIsInteger ? `Производител № ${id}` : 'Нов производител';
    }

    return (
        <SingleEditLayout
            title={getTitle()}
            showForm={() => showForm()}
            onSubmit={handleSubmit}
            stateErrors={stateErrors}
            navComponent={CarManufacturerNav}
            navProps={{id: id, activeKey: 'edit'}}
        >
            <CarManufacturerForm
                carManufacturer={carManufacturer}
                errors={errors}
                onAttributeChange={handleAttributeChange}
            />

        </SingleEditLayout>);
};

export default CarManufacturerEditPage;
