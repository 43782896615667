import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import OrderNav from "./components/OrderNav.js";
import SingleFilesLayout from "../../../components/Layouts/SingleFilesLayout.js";
import {fetchFiles, resetStateData as resetFilesStateData} from '../../../slices/fileListSlice.js';

const OrderFilesPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: order, loading, errors: stateErrors} = useSelector((state) => state.order);
    const {data: files, loading: filesLoading} = useSelector((state) => state.fileList);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Поръчка № ${id}`);
    }, [order.id]);

    useEffect(() => {
        return () => {
            dispatch(resetFilesStateData());
        };
    }, [id, dispatch, idIsInteger]);

    const showPage = () => {
        return !loading && !filesLoading;
    }

    return (
        <SingleFilesLayout
            title={title}
            showPage={showPage()}
            stateErrors={stateErrors}
            navComponent={OrderNav}
            navProps={{id: id, activeKey: 'files'}}
            item = {order}
            itemsPath={'orders'}
            files={files}
            onFilesUpdate={() => dispatch(fetchFiles({id: id, filesItemPath: 'orders'}))}
        />
    );
};

export default OrderFilesPage;
