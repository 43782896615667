import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import { changeAttributeReducer, resetStateDataReducer } from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        name: '',
        synonyms: ''
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchCarManufacturer = fetchItem('carManufacturer/fetchCarManufacturer', '/car-manufacturers')
export const saveCarManufacturer = saveItem('carManufacturer/saveCarManufacturer', '/car-manufacturers');

const carManufacturerItem = createSlice({
    name: 'carManufacturerItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchCarManufacturer, saveCarManufacturer)
    },
});

export default carManufacturerItem.reducer;
export const {
    changeAttribute,
    resetStateData
} = carManufacturerItem.actions;