import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {hasPermission} from "../../../../utils/permissions.js";

const ArticleNav = (
    {
        id,
        activeKey
    }) => {
    const { user } = useSelector((state) => state.auth);

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="view">
            {id !== 'create' && (

                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'view'} to={`/articles/${id}`}>Преглед</Nav.Link>
                </Nav.Item>
            )}
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/articles/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
            {id !== 'create' && (
                <>
                    {hasPermission(user, 'get-orders') && (
                        <Nav.Item>
                            <Nav.Link as={Link} eventKey={'orders'} to={`/articles/${id}/orders`}>Поръчки</Nav.Link>
                        </Nav.Item>
                    )}
                    <Nav.Item>
                        <Nav.Link as={Link} eventKey={'files'} to={`/articles/${id}/files`}>Файлове</Nav.Link>
                    </Nav.Item>
                    {hasPermission(user, 'get-article-stocks') && (
                    <Nav.Item>
                        <Nav.Link as={Link} eventKey={'stocks'} to={`/articles/${id}/stocks`}>Наличност</Nav.Link>
                    </Nav.Item>
                    )}

                </>
            )}
        </Nav>
    )
}

export default ArticleNav;