import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    errors: []
}
export const fetchClients = fetchItems('clients/fetchClients', '/clients');

const clientListSlice = createSlice({
    name: 'clients',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchClients)
    },
});
export const {
    setCurrentPage,
    resetStateData
} = clientListSlice.actions;

export default clientListSlice.reducer;