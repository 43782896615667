import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import FormInput from "../../../../components/Elements/FormInput.js";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";


const UserForm = (
    {
        user,
        onAttributeChange,
        errors,
        roles = []
    }
) => {
    const userHasRole = (role) => {
        return user.roles.some(userRole => userRole === role.id);
    }

    const handleUserRoleChange = (role, isChecked) => {
        let roles = [...user.roles];
        if (isChecked) {
            if (!roles.includes(role.id)) {
                roles.push(role.id);
            }
        } else {
            roles = roles.filter(userRole => userRole !== role.id);
        }
        onAttributeChange('roles', roles);
    }

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col md={6}>
                        <FormInput
                            label={'Име'}
                            name={'name'}
                            value={user.name}
                            onChange={onAttributeChange}
                            errors={errors}
                            />
                    </Col>
                    <Col md={6}>
                        <FormInput
                            label={'Email'}
                            name={'email'}
                            value={user.email}
                            onChange={onAttributeChange}
                            errors={errors}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormInput
                            label={'Парола'}
                            name={'password'}
                            value={user.password}
                            onChange={onAttributeChange}
                            errors={errors}
                            type={'password'}
                        />
                    </Col>
                    <Col md={6}>
                    <FormInput
                            label={'Повтори Парола'}
                            name={'confirmPassword'}
                            value={user.confirmPassword}
                            onChange={onAttributeChange}
                            errors={errors}
                            type={'password'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormCheckbox
                            name={'isAdmin'}
                            label={'Админ'}
                            isChecked={user.isAdmin}
                            onCheckboxChange={onAttributeChange}
                        />
                        <FormCheckbox
                            name={'isActive'}
                            label={'Активен'}
                            isChecked={user.isActive}
                            onCheckboxChange={onAttributeChange}
                        />
                    </Col>
                </Row>
                <Row className={'m5-2'}>
                    <Col md={12}>
                        <div>Роли:</div>
                        {roles.map((role) => (
                            <div className={'form-check-inline'} key={`role-${role.id}`}>
                            <FormCheckbox
                                name={`role-${role.id}`}
                                label={role.name}
                                isChecked={userHasRole(role)}
                                onCheckboxChange={(name, value) => {
                                    handleUserRoleChange(role, value);
                                }}
                            />
                            </div>
                        ))}
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )
}

export default UserForm;