import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

const SearchQueryInputAutoComponent = (
    {
        onQueryChange,
        query = ''
    }) => {

    const handleQueryChange = (e) => {
        const value = e.target.value;
        clearTimeout(window.debounceTimeout);
        window.debounceTimeout = setTimeout(() => {
            if (value.length > 1 || !value) {
                onQueryChange(value);
            }
        }, 750);
    };

    return (
        <Form className={'mt-3 mb-3'}>
            <Row className="align-items-center">
                <Col xs="auto">
                    <Form.Label className="mr-2">Търси:</Form.Label>
                </Col>
                <Col xs="auto">
                    <Form.Control defaultValue={query} onChange={handleQueryChange}/>
                </Col>
            </Row>
        </Form>
    );
};

export default SearchQueryInputAutoComponent;