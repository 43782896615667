import {Button, Card } from "react-bootstrap";
import React, {useState} from "react";
import {toFloatBgn} from "../../../../utils/functions.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import {formatDateToBeautifulString} from "../../../../utils/dateFunctions.js";
import {CardImage, EyeFill, PencilSquare} from "react-bootstrap-icons";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {Link} from "react-router-dom";
import OrderStatusSelect from "../../Single/components/fields/OrderStatusSelect.js";
import OrderStatusTableModal from "./OrderStatusTableModal.js";
import OrderDetailsTableModal from "./OrderDetailsTableModal.js";
import StandardTable from "../../../../components/StandardTable.js";

const OrdersTable = (
    {
        data,
        onItemSave,
        showTable = () => true
    }) => {

    const headers = ['№', 'Клиент', 'Кола', 'Дата приемане', 'Статус', 'Дата издаване', 'Изпълнител', 'Фактура', '', 'Стойност', ''];

    const [orderStatusTableModalData, setOrderStatusTableModalData] = useState({
        showOrderStatusTableModal: false,
        newOrderStatus: null,
        editOrder: null
    });

    const [orderDetailsModalData, setOrderDetailsModalData] = useState({
        showOrderDetailsModal: false,
        order: null
    });

    return (
        <>
            <Card>
                {showTable ? (
                    <StandardTable headers={headers}>
                        {data.map((order) => (
                            <tr key={order.id}>
                                <td>{order.id}</td>
                                <td><Link to={`/clients/${order.client.id}`}
                                          target={'_blank'}>{order.client.name}</Link><br/>{order.client.phoneNumber}
                                </td>
                                <td>
                                    {order.car &&
                                        <Link to={`/cars/${order.car?.id}`}
                                              target={'_blank'}>{order.car?.carModel.carManufacturer.name} {order.car?.carModel.name} - {order.car?.registrationNumber}</Link>
                                    }
                                </td>
                                <td>{order.startDate ? formatDateToBeautifulString(order.startDate) : ''}</td>
                                <td className={'bg-info-subtle'} style={{'minWidth': '100px'}}>
                                    <OrderStatusSelect
                                        order={order}
                                        onOrderStatusChange={(name, selectedStatus) => {
                                            setOrderStatusTableModalData({
                                                showOrderStatusTableModal: true,
                                                newOrderStatus: selectedStatus,
                                                editOrder: order
                                            });
                                        }}
                                        hideLabel={true}
                                    />
                                </td>
                                <td className={'bg-info-subtle'}>{order.endDate ? formatDateToBeautifulString(order.endDate) : ''}</td>
                                <td>{order.partner.name}</td>
                                <td>
                                    <span>{order.invoiceDate ?  formatDateToBeautifulString(order.invoiceDate) : ''}</span>
                                    { order.invoiceNumber &&
                                        <span className={'d-block'}>№ {order.invoiceNumber}</span>
                                    }
                                </td>
                                <td>
                                    <Button className={'small-button'} onClick={() => setOrderDetailsModalData({
                                        showOrderDetailsModal: true,
                                        order: order
                                    })}>
                                        Виж детайли
                                    </Button>
                                </td>
                                <td>{toFloatBgn(order.total)}</td>
                                <td>
                                    <TableLinkIconButton to={`/orders/${order.id}`}><EyeFill/></TableLinkIconButton>
                                    <TableLinkIconButton
                                        to={`/orders/${order.id}/edit`}><PencilSquare/></TableLinkIconButton>
                                    <TableLinkIconButton
                                        to={`/orders/${order.id}/files`}><CardImage/></TableLinkIconButton>
                                </td>
                            </tr>
                        ))}
                    </StandardTable>
                ) : (
                    <LoadingComponent/>
                )}
            </Card>
            {orderStatusTableModalData.showOrderStatusTableModal &&
                <OrderStatusTableModal
                    orderStatus={orderStatusTableModalData.newOrderStatus}
                    onClose={() => {
                        setOrderStatusTableModalData({
                            showOrderStatusTableModal: false,
                            newOrderStatus: null,
                            editOrder: null
                        });
                    }}
                    show={orderStatusTableModalData.showOrderStatusTableModal}
                    editOrder={orderStatusTableModalData.editOrder}
                    onOrderSave={onItemSave}
                />
            }
            {orderDetailsModalData.showOrderDetailsModal &&
                <OrderDetailsTableModal
                    order={orderDetailsModalData.order}
                    show={orderDetailsModalData.showOrderDetailsModal}
                    onClose={() => {
                        setOrderDetailsModalData({
                            showOrderDetailsModal: false,
                            order: null
                        });
                    }}
                />
            }
        </>
    )
};

export default OrdersTable;