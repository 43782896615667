import Modal from "react-bootstrap/Modal";
import React from "react";

const OrderDetailsTableModal = (
    {
        show,
        onClose,
        order
    }
) => {

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>Поръчка № {order.id} - {order.client.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {order.insurer ? (
                        <>
                            <div><b>Застраховател:</b> {order.insurer?.name}</div>
                            <div><b>Номер щета:</b> {order.insurerClaimId ? order.insurerClaimId : 'частен ремонт'}
                            </div>
                        </>
                    ) : (
                        <div>Частен ремонт</div>
                    )}
                </p>
                <p>
                    <div><b>Клиент фактура:</b> {order.invoiceClient?.name}</div>
                    <div><b>Номер фактура:</b> {order.invoiceNumber}</div>
                </p>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
};

export default OrderDetailsTableModal;