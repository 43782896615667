import React from 'react';
import { Form } from 'react-bootstrap';
import {v4 as uuidv4} from "uuid";

function FormInput(
    {
        label,
        name,
        value,
        onChange,
        helpText = '',
        errors = [],
        type = 'text',
        as = '',
        disabled = false
    }) {
    const elementId = uuidv4();

    const handleChange = (e) => {
        onChange(name, e.target.value)
    }

    return (
        <Form.Group className={'align-items-center mb-2'}>
            {label && (
                <Form.Label className={'me-2'}>{label}</Form.Label>
            )}
            <Form.Control
                id={`edit-${elementId}`}
                name={`edit-${elementId}`}
                value={value === null ? '' : value}
                onChange={handleChange}
                type={type}
                isInvalid={!!errors[name]}
                as={as ? as : 'input'}
                rows={as === 'textarea' ? 5 : undefined}
                autoComplete={`new-${name}`}
                disabled={disabled}
            />
            {helpText && (
                <Form.Text className="text-muted">
                    {helpText}
                </Form.Text>
            )}
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default FormInput;
