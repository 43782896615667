import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";
import React from "react";

const CarManufacturerForm = ({
    carManufacturer,
    onAttributeChange,
    errors
                             }) => {
    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col md={3}>
                        <FormInput label="Име" name="name" value={carManufacturer.name}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row> <Col md={8}>
                    <FormInput label="Синоними" name="synonyms"
                               value={carManufacturer.synonyms}
                               onChange={onAttributeChange} errors={errors}
                               as={'textarea'}/>
                </Col>
                </Row>
            </Form.Group>
        </Form>
    );
};

export default CarManufacturerForm;