import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import { changeAttributeReducer, resetStateDataReducer } from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        name: '',
        synonyms: ''
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchCarModel = fetchItem('carModel/fetchCarModel', '/car-models')
export const saveCarModel = saveItem('carModel/saveCarModel', '/car-models');

const carModelItem = createSlice({
    name: 'carModelItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchCarModel, saveCarModel)
    },
});

export default carModelItem.reducer;
export const {
    changeAttribute,
    resetStateData
} = carModelItem.actions;