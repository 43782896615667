import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    errors: []
}

export const fetchUsers = fetchItems(
    'users/fetchUsers', '/users'
);

const userListSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        resetStateData: resetStateDataReducer(initialState),
        setCurrentPage: setCurrentPageReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchUsers)
    },
});

export const {
    setCurrentPage,
    resetStateData
} = userListSlice.actions;

export default userListSlice.reducer;