import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import {changeAttributeReducer, resetStateDataReducer} from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        name: '',
        email: '',
        password: '',
        confirmPassword:'',
        isAdmin: null,
        isActive: null,
        permissions: [],
        roles: []
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchUser = fetchItem('user/fetchUser', 'users')
export const saveUser = saveItem('user/saveUser', '/users')

const userItem = createSlice({
    name: 'userItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchUser, saveUser)
    },
});

export default userItem.reducer;
export const {
    changeAttribute,
    resetStateData
} = userItem.actions;