import Form from "react-bootstrap/Form";
import {getCountries} from "../../data/contries.js";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "./FormInput.js";

const PhoneNumberInput = ({
    label = 'Телефон',
    value,
    name,
    onChange,
    errors
                          }) => {
    const countries = getCountries();

    let areaCodeNumeric = 359;
    let number = '';
    if (value) {
        const parts = value.split('-');
        if (parts.length === 1) {
            number = value;
        } else {
            areaCodeNumeric = parts[0].replace('+','');
            number = parts[1];
        }

    }

    const handlePhoneNumberChange = (name, value) => {
        if (name === 'areaCodeInput') {
            areaCodeNumeric = value;
        }
        if (name === 'numberInput') {
            number =  value.replace(/^0+/, '').replace(/\D+/g, '');
        }
        const phoneNumber = `+${areaCodeNumeric}-${number}`;
        onChange('phoneNumber', phoneNumber);
    }

    return (
        <Form.Group className={'mb-2'}>
            <Form.Label>{label}</Form.Label>
            <Row>
                <Col xs={4}>
                    <Form.Select
                        name={'areaCodeInput'}
                        value={areaCodeNumeric ? areaCodeNumeric : 359}
                        onChange={handlePhoneNumberChange}>
                        {countries.map((country, index) => (
                            <option key={index} value={country.code}>+{country.code} {country.name}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col xs={8}>
                    <FormInput
                        name={'numberInput'}
                        value={number}
                        errors={errors}
                        onChange={handlePhoneNumberChange}
                    />
                    <Form.Control type={'hidden'} value={value} isInvalid={!!errors[name]} />
                    <Form.Control.Feedback type="invalid">
                        {errors[name]}
                    </Form.Control.Feedback>
                </Col>
            </Row>

        </Form.Group>
    )
};

export default PhoneNumberInput;