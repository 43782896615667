import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchSupplier, resetStateData, saveSupplier} from "../../../slices/supplierSlice.js";
import {processIdInformation, processSubmit} from "../../../utils/functions.js";
import SupplierForm from "./components/SupplierForm.js";
import {getValidationSchema} from "../../../utils/validation.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import SupplierNav from "./components/SupplierNav.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";

const SupplierEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: supplier, loading, errors: stateErrors, status} = useSelector((state) => state.supplier);
    //    const {data: carModel, loading, errors: stateErrors, status} = useSelector((state) => state.carModel)
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const supplierSchema = getValidationSchema({
        name: ['required', ['max', 50], ['min', 2]]
    })

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchSupplier({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(setSuccessMessage(`Успешно запазен прозиводител ${supplier.name}`));
            dispatch(resetStateData());
            navigate('/settings/suppliers');
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, supplierSchema, saveSupplier, dispatch, supplier);
    };

    const showForm = () => {
        return !loading;
    }

    const getTitle = () => {
        return idIsInteger ? `Доставчик № ${id} - ${supplier.name}` : 'Нов доставчик';
    }

    return (
        <SingleEditLayout
            title={getTitle()}
            showForm={() => showForm()}
            onSubmit={handleSubmit}
            stateErrors={stateErrors}
            navComponent={SupplierNav}
            navProps={{id: id, activeKey: 'edit'}}
        >
            <SupplierForm
                supplier={supplier}
                errors={errors}
                onAttributeChange={handleAttributeChange}
            />

        </SingleEditLayout>);
};

export default SupplierEditPage;
