import React from 'react';
import { useSelector} from 'react-redux';
import {fetchArticles, resetStateData} from "../../../slices/articleListSlice.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import ArticlesTable from "./components/ArticlesTable.js";
import ArticleTableFilters from "./components/ArticleTableFilters.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const ArticleListPage = () => {
    const {data: articles, loading, pagination} = useSelector((state) => state.articleList);

    return (
        <div>
            <ListHeaderLayout
                title={'Артикули'}
                createLink={'/articles/create/edit'}
            />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchArticles}
                loading={loading}
                FiltersComponent={ArticleTableFilters}
                TableComponent={ArticlesTable}
                pagination={pagination}
                data={articles}
            />
        </div>
    );
};

export default ArticleListPage;
