import Button from "react-bootstrap/Button";
import React from "react";
import {toFloatBgn} from "../../../../utils/functions.js";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";

const OrderLinesTableComponent = (
    {
        lines,
        showingActiveLines,
        onEditLine,
        onRemoveOrderLine,
        viewOnly = false
    }) => {

    const handleEditLineClick = (line) => {
        onEditLine(line);
    };

    const removeOrderLine = (line) => {
        onRemoveOrderLine(line)
    }

    return (
        <div className={'mt-3'}>
            <Table hover bordered striped>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Код</th>
                    <th>Стока/Услуга</th>
                    <th>Бележка</th>
                    <th>Мярка</th>
                    <th>Количество</th>
                    <th>Цена</th>
                    <th>Покупна Цена</th>
                    <th>Отстъпка</th>
                    <th>Вид</th>
                    <th>Приход/Разход</th>
                    <th>Сума</th>
                    <th></th>

                </tr>
                </thead>
                <tbody>
                {lines
                    .filter(orderLine => orderLine.isActive === showingActiveLines)
                    .map((orderLine, index) => (
                        <tr key={orderLine.id} className={isNaN(orderLine.id) ? 'temporary-order-line' : ''}>
                            <td>{index + 1}</td>
                            <td><Link to={`/articles/${orderLine.article.id}`} target={'_blank'}>{orderLine.article.skuNumber}</Link></td>
                            <td>
                                <div>{orderLine.article.name}</div>
                                {orderLine.article?.articleType.label === 'car_detail' && (
                                    <div><i className={'small'}>Степен увреждане: {orderLine.damageLevel.name}</i></div>
                                )}
                            </td>
                            <td>{orderLine.additionalInformation}</td>
                            <td>{orderLine.article.quantityType.name}</td>
                            <td>{orderLine.quantity}<br/>{orderLine.useStock ? 'от склад' : 'не ползва склад'}</td>
                            <td>{toFloatBgn(orderLine.price)}</td>
                            <td>{toFloatBgn(orderLine.costPrice)}</td>
                            <td>{orderLine.discount}%</td>
                            <td>{orderLine.orderLineType.name}</td>
                            <td>{orderLine.orderLineType.isCost ? 'Разход' : 'Приход'}</td>
                            <td>{orderLine.orderLineType.isCost ? '- ' : ''}{toFloatBgn(orderLine.totalPrice)}</td>
                            {showingActiveLines && !viewOnly ? (
                                <td>
                                    <Button className={'me-1'} variant="success"
                                            onClick={() => handleEditLineClick(orderLine)}>Редактирай</Button>
                                    <Button variant="danger" onClick={() => {
                                        removeOrderLine(orderLine)
                                    }}>Премахни</Button>
                                </td>
                            ) : <td></td>}
                        </tr>
                    ))}
                </tbody>
            </Table>

        </div>
    )
};

export default OrderLinesTableComponent;