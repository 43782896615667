import React, {useState} from 'react';
import apiClient from "../../../axios.js";
import {Button} from "react-bootstrap";
import HelpText from "../../../components/Elements/HelpText.js";

const DocumentPdf = (
    {
        id,
        type,
        details,
        helpText = ''
    }) => {
    const [documentLoading, setDocumentLoading] = useState(false);

    const downloadPdf = async () => {
        setDocumentLoading(true);
        try {
            const response =  await apiClient.get(`/documents/generate/${id}/${type}?details=${details}`, {
                headers: {
                    'Accept': 'application/pdf'
                },
                responseType: 'blob'
            });
            const status = await response.status;
            if (status !== 200) {
                throw new Error('Network response was not ok');
            }

            const url = window.URL.createObjectURL(response.data);

            const newWindow = window.open(url);
            if (newWindow) newWindow.focus();

        } catch (error) {
            console.error('There was an error downloading the PDF:', error);
        } finally {
            // Always enable the button, regardless of success or error
            setDocumentLoading(false);
        }
    };

    const getButtonText = () => {
        let text = '';
        if (type === 'proforma') {
            text = 'Проформа';
            if (details) {
                text += ' калкулация';
            }
        } else if (type === 'invoice') {
            text = 'Фактура';
        }
        return text;
    }

    return (
        <div>
            <Button onClick={downloadPdf} className={'me-2'} variant={type === 'proforma' ? 'success' : 'primary'} disabled={documentLoading}>
            {getButtonText()}
            </Button>
            <HelpText>{helpText}</HelpText>
        </div>

    );
};

export default DocumentPdf;
