import SelectInput from "../../../../../components/Elements/SelectInput.js";
import React from "react";
import {useSelector} from "react-redux";


const OrderStatusSelect = (
    {
        order,
        onOrderStatusChange,
        errors = [],
        hideLabel = false
    }) => {

    const {data: orderStatuses} = useSelector((state) => state.orderStatusList);

    const availableStatuses = orderStatuses.filter((orderStatus) => {
        return !(
            (orderStatus.label === 'cancelled' && order.id === null) ||
            (orderStatus.label === 'invoiced' && order.hasInvoice === false)
        );
    });

    return (
        <div className={'force-text-wrap-auto'}>
        <SelectInput
            label={hideLabel ? '' : 'Статус'}
            value={order.orderStatus ? order.orderStatus.id : ''}
            name={'orderStatus'}
            errors={errors}
            onChange={onOrderStatusChange}
            selectOptions={availableStatuses}
        />
        </div>
    )
};

export default OrderStatusSelect;